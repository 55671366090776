import { useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import styled from "styled-components";
import { DataTableChart } from "../../charts/datatable";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { copyWithToast, formatedDateTime, formatedPrice, labelCreditoSolicitado, isMobileDevice, toastError } from "../../utils";
import { Button } from "../../new_components/Button";
import { BsInformationModal } from "../../components/BsInformationModal";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { BsCircularLoadingModal } from "../../components/BsCircularLoadingModal";
import UpdateIcon from '@mui/icons-material/Update';

const BoxShowQrCode = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
`;

const ImgQrCode = styled.img`
  max-height: 250px;
`;

const PlanoCard = styled.div`
  background-color: ${({ theme }) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : '#fff'};
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
`;

const Titulo = styled.h3`
  font-size: 14px;
  background-color: #6c63ff;
  color: #fff;
  padding: 6px 10px;
  border-radius: 4px;
  display: inline-block;
`;

const Description = styled.div`
  font-size: 14px;
`;

const Preco = styled.p`
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
`;

export const PlanosRenovacao = (data?: any) => {
  const auth = useAuth();
  const theme = useTheme();

  const reftable = useRef<any>();

  const [planos, setPlanos] = useState<any>([]);
  const [renovacao, setRenovacao] = useState<any>();
  const [loading, setLoading] = useState<any>(false);

  const columns = [
    { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
    { field: 'data', header: 'Emissão', sortable: true, body: (e: any) => formatedDateTime(e.data) },
    { field: 'quantidade', header: 'Nº Meses', sortable: true },
    { field: 'valor', header: 'Valor', sortable: true, body: (e: any) => formatedPrice(e.pix.valor) },
    { field: 'situacao', header: 'Situação', body: (e: any) => labelCreditoSolicitado(e.situacao), style: { width: '125px' } },
  ];

  useEffect(() => {
    const getAssinaturas = async () => {
      try {
        const response: any = await auth.requestGet(`/master/valorAssinatura?contador=${data.data ? data.data.contador : auth.masterId}`);
        setPlanos(response.data.dados);
      } catch (error) {
        toastError(error);
      }
    };

    const getHistorico = async () => {
      try {
        const response: any = await auth.requestGet(`/creditos`);
        reftable.current.setData(response.data.dados);
      } catch (error) {
        toastError(error);
      }
    };

    getAssinaturas();

    if (!data.data)
      getHistorico();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handlePayClick = async (plano: any) => {
    try {
      setLoading(true);
      var body: any = { periodo: plano };

      if (data.data) {
        body.masterContador = data.data.contador;
        body.usuContador = data.data.usuarioContador;
      }

      const response: any = await auth.requestPost('/credito/solicitar', body);
      setRenovacao(response.data.dados);
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    planos && (
      <Grid container spacing={3} sx={{ padding: '8px' }}>
        {planos?.opcoes?.map((plano: any, index: number) => (
          <Grid item xs={isMobileDevice() ? 12 : 4} key={index}>
            <PlanoCard theme={theme}>
              <Titulo>
                {plano.periodo.toUpperCase() +
                  (plano.desconto > 0 ? ` (com ${plano.desconto}% de desconto)` : '')}
              </Titulo>
              {plano.produtos.map((produto: any, index: number) => (
                <Description key={index}>{produto.descricao}</Description>
              ))}
              <Preco>R$ {plano.valorTotal.toFixed(2)}</Preco>
              <Button
                onClick={() => handlePayClick(plano.periodo.toLowerCase())}
                color={'#05833e'}
                style={{ fontSize: '14px' }}
              >
                PAGAR COM PIX
              </Button>
            </PlanoCard>
          </Grid>
        ))}

        <BsCircularLoadingModal open={loading} />

        {renovacao &&
          <BsInformationModal
            open={true}
            title={`Pix: ${renovacao.pix.descricao}`}
            maxWidth={'xs'}
            children={
              <BoxShowQrCode>
                <Typography variant="button">{`Valor: R$ ${renovacao.pix.valor}`}</Typography>
                <ImgQrCode alt="QR Code" src={`data:image/png;base64,${renovacao.pix.qrCode}`} />
                <div style={{ marginTop: '7px' }}>
                  <Typography variant="button">PIX COPIA E COLA</Typography>
                  <IconButton
                    sx={{ ml: '7px', border: `1px solid ${theme.palette.mode === 'dark' ? '#fff' : '#3d3d3d'}` }}
                    onClick={() => copyWithToast(renovacao.pix.copiaCola, 'PIX copiado com sucesso.')}
                  >
                    <ContentCopyOutlinedIcon />
                  </IconButton>
                </div>
              </BoxShowQrCode>
            }
            close={() => setRenovacao(null)}
          />
        }

        {!data.data &&
          <Grid item xs={12}>
            <DataTableChart ref={reftable} icon={<UpdateIcon />} title="Histórico de Renovações" columns={columns} rows={5} sortField={'contador'} sortOrder={-1} />
          </Grid>
        }
      </Grid>
    )
  );
};
