
import styled from "styled-components";
import { formatedDateTime, formatedPrice } from "../../../../utils";
import Produto from '../../../../images/Produto.png';
import { BsCheckCircleFill } from "react-icons/bs";
import Logo from '../../../../icones/BitSafiraPagamentos.png';
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import { BsLoadingButton } from "../../../../components/BsLoadingButton";
import { BsTextField } from "../../../../components/BsBaseField";
import { useState } from "react";
import { Api } from "../../../../services/api";

export const DivCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const Empresa = styled(DivCenter)`
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 10px;
`

const LogoEmrpesa = styled.img`
    margin-right: 10px;
    border-radius: 10px;
`

const LabelCompraSegura = styled(DivCenter)`
    background-color:#FEFEFE;
    text-align: center;
    padding: 3px 0;
    border-radius: 4px;
`

const ImgPedido = styled.img`
    padding: 15px;
`

const Texto = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    text-align: center;
`

const Titulo = styled(Texto)`
    font-size: 18px;
    font-weight: 500;
`

const Divider = styled(DivCenter)`
    display: flex;
    height: 2px;
    width: 300px;
    background-color: #75757586;
    margin-top: 10px;
    margin-bottom: 5px;
`

export const DadosEmpresa = (props: any) => {
    return (
        <div>
            <Empresa>
                {props.logo && <LogoEmrpesa src={props.logo} height={'60px'} alt="LogoEmpresaParceira" />}
                {props.empresa}
            </Empresa>
            <LabelCompraSegura>COMPRA SEGURA</LabelCompraSegura>
            <DivCenter >
                <ImgPedido src={Produto} height={'200px'} style={{ display: 'flex' }} />
            </DivCenter>
            <Titulo>Sua Compra</Titulo>
            <Texto>1 Item</Texto>
            <Divider />
            <DivCenter style={{ justifyContent: 'space-between', marginBottom: '30px' }}>
                <div>{props.servico}</div>
                <div>{formatedPrice(props.valor)}</div>
            </DivCenter>
            <Divider />
            <DivCenter style={{ justifyContent: 'space-between', fontSize: '16px', fontWeight: 600 }}>
                <div>Total</div>
                <div>{formatedPrice(props.valor)}</div>
            </DivCenter>
        </div>
    )
}

export const DadosCliente = (props: any) => {
    const params: any = new URLSearchParams(window.location.search);

    const [email, setEmail] = useState<any>();
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    async function pagarClick() {
        setSubmitted(true);

        if (!email)
            return;

        let body = {
            token: params.get('token'),
            email
        }

        setLoading(true);

        await Api.post('clienteServico/pagar', body)
            .then((response: any) => {
                props.setDadosPagamento(response.data.dados)
            })
            .catch((error: any) => {
                toast.error(error?.response?.data?.mensagem || 'Não foi possível concluir a operação');
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <div>
            <Titulo>INFORMAÇÕES DO CLIENTE</Titulo>
            <Divider style={{ marginBottom: '15px' }} />
            <DivCenter style={{ justifyContent: 'space-between', marginBottom: '15px' }}>
                <div>Nome:</div>
                <div>{props.nome.toUpperCase()}</div>
            </DivCenter>
            <DivCenter style={{ justifyContent: 'space-between', marginBottom: '15px' }}>
                <div>Serviço:</div>
                <div>{props.servico}</div>
            </DivCenter>
            <DivCenter style={{ justifyContent: 'space-between', marginBottom: '15px' }}>
                <div>Vencimento:</div>
                <div>{formatedDateTime(props.dataExpiracao)}</div>
            </DivCenter>
            <DivCenter style={{ justifyContent: 'space-between', marginBottom: '15px' }}>
                <div style={{ width: '80px' }}>E-mail:</div>
                <BsTextField value={email} maxLength={50} type={'text'} variant={'standard'} submitted={submitted} required validate onChange={(e: any) => setEmail(e.target.value)} />
            </DivCenter>
            <DivCenter style={{ marginTop: '40px' }} >
                <BsLoadingButton loading={loading} fullWidth onClick={() => pagarClick()}>PAGAR</BsLoadingButton>
            </DivCenter>
            <DivCenter style={{ marginTop: '50px' }}>
                <img src={Logo} height={50} alt="LogoBitSafiraPagamentos" />
            </DivCenter>
        </div>
    )
}

export const DadosPagamento = (props: any) => {
    return (
        <div>
            <Titulo>DADOS PARA PAGAMENTO</Titulo>
            <Divider style={{ marginBottom: '15px' }} />
            <img src={props.qrCode} width={300} alt="qrCodePagamento" />
            <DivCenter style={{ marginTop: '10px' }} >
                <BsLoadingButton fullWidth
                    onClick={() => {
                        copy(props.copiaCola);
                        toast.success('PIX copiado com sucesso.')
                    }}
                >COPIA E COLA
                </BsLoadingButton>
            </DivCenter>
            <Texto>Validade: {formatedDateTime(props.dataValidade)}</Texto>
            <DivCenter style={{ marginTop: '50px' }}>
                <img src={Logo} height={50} alt="LogoBitSafiraPagamentos" />
            </DivCenter>
        </div>
    )
}

export const PagamentoConfirmado = (props: any) => {
    return (
        <div>
            <DivCenter>
                <BsCheckCircleFill style={{ fontSize: '50px', color: '#206EAD' }} />
            </DivCenter>
            <Titulo>PAGAMENTO CONFIRMADO</Titulo>
            <DivCenter style={{ marginTop: '35px' }}>
                <img src={Logo} height={50} alt="LogoBitSafiraPagamentos" />
            </DivCenter>
        </div>
    )
}