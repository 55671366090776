import { useAuth } from '../../contexts/AuthProvider/useAuth'
import { useRef, useState } from 'react'
import Logo from '../../icones/Logo.png'
import { Card, Form, Row } from "react-bootstrap"
import { Input } from '../../new_components/Input'
import { Button } from '../../new_components/Button'
import { Container, Wrapper, LogoPanel } from '../Login'
import { isMobileDevice, toastError } from '../../utils'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'

const LocalWrapper = styled(Wrapper)`
    max-width: ${isMobileDevice() ? '100%' : '500px'};
`

export const Register = () => {
    const params: any = new URLSearchParams(window.location.search);
    const auth = useAuth();
    const navigate = useNavigate();

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const nomeref = useRef<any>();
    const usuarioref = useRef<any>();
    const senharef = useRef<any>();
    const telefoneref = useRef<any>();
    const emailref = useRef<any>();

    async function register() {
        setLoading(true);

        let body = {
            login: usuarioref.current.getValue(),
            senha: senharef.current.getValue(),
            email: emailref.current.getValue(),
            nome: nomeref.current.getValue(),
            whatsapp: telefoneref.current.getValue(),
            identificador: params.get('masterid')
        }

        await auth.requestPost('master/registrar', body)
            .then((response: any) => {
                toast.success('Cadastro foi realizado com sucesso.');
                navigate('/');
            })
            .catch((error: any) => {
                toastError(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }


    const registerClick = (e: any) => {
        const form = e.currentTarget;

        if (form.checkValidity() === true)
            register()
        else
            setValidated(true);

        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <>
            <Helmet>
                <title>BitSafira - Cadastro</title>
                <meta name="description" content="Cadastre-se e tenha acesso a uma plataforma integrada para gerenciamento eficiente de clientes IPTV, serviços, notificações e cobranças automáticas via WhatsApp. Controle assinaturas, mensalidades e aluguel com facilidade e automação." />
            </Helmet>
            <Container>
                <LocalWrapper>
                    <Form noValidate validated={validated} className="w-100" onSubmit={registerClick}>
                        <Row className="mb-2 justify-content-center">
                            <LogoPanel src={Logo} />
                        </Row>
                        <Row className="justify-content-center text-center">
                            <Card className="border-0 p-0">
                                <Card.Body>
                                    <h1 style={{ fontSize: '20px' }}>CRIE SEU CADASTRO</h1>
                                    <Card.Text>Para ser cliente é necessário preencher corretamente o formulário abaixo com os respectivos dados.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Row>
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <Input ref={nomeref} validated={validated} id="nome" type="text" label="Nome" required />
                            </Grid>
                            <Grid item xs={isMobileDevice() ? 12 : 6}>
                                <Input ref={telefoneref} validated={validated} id="telefone" type="text" label="Telefone" model={'phone'} required defaultValue={'+55'} placeholder={'+55 99 9999-9999'} />
                            </Grid>
                            <Grid item xs={isMobileDevice() ? 12 : 6}>
                                <Input ref={emailref} validated={validated} id="email" type="text" label="E-mail" model={'email'} required />
                            </Grid>
                            <Grid item xs={isMobileDevice() ? 12 : 6}>
                                <Input ref={usuarioref} validated={validated} id="usuario" type="text" label="Usuário" required rejectSpace />
                            </Grid>
                            <Grid item xs={isMobileDevice() ? 12 : 6}>
                                <Input ref={senharef} validated={validated} id="senha" type="password" label="Senha" required />
                            </Grid>
                            <Grid item xs={12} >
                                <Button className="mt-2 w-100" disabled={loading} type="submit" >{loading ? 'CADASTRANDO...' : 'CADASTRAR'}</Button>
                                <div style={{ fontSize: '14px' }}>Voltar para a tela de Login:
                                    <Link style={{ textDecoration: 'none', color: 'var(--primary-color)' }} to='/'>{' clique aqui.'}</Link>
                                </div>
                            </Grid>
                        </Grid>
                    </Form>
                </LocalWrapper>
            </Container>
        </>
    )
}