import { Drawer, DrawerProps } from "@mui/material";
import React from "react";
import { lighten } from "polished";
import { getCSSVariableValue } from "../../utils";

export const DRAWER_WIDTH = 235;

export const BsDrawer: React.FC<DrawerProps> = (props) => {
    return (
        <Drawer
            anchor={'left'}
            sx={{
                width: DRAWER_WIDTH,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: DRAWER_WIDTH,
                    background: 'var(--primary-color)',
                    boxSizing: 'border-box',
                    borderRight: '0px',
                    '&::-webkit-scrollbar': {
                        width: '8px'
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'var(--primary-color)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: lighten(.1, getCSSVariableValue('var(--primary-color)')),
                        borderRadius: '3px'
                    },
                },
            }}
            PaperProps={{
                style: { backgroundColor: 'var(--primary-color)', color: '#fff' }
            }}
            {...props}
        />
    )
}