import { BsColumns, ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { MODEL_INSTANCIA } from "../../../models";
import { intToSessaoWhatsApp, labelSessaoWhatsApp, toastError } from "../../../utils";
import { useAuth } from '../../../contexts/AuthProvider/useAuth';
import { DESCONECTAR_INSTANCIA } from '../../../routes_api';
import { useRef } from 'react';
import { QrCode } from './QrCode';
import { MensagemTeste } from './MensagemTeste';
import { toast } from 'react-toastify';
import { IoQrCode } from "react-icons/io5";
import { IoMdPower } from "react-icons/io";
import { LuMessagesSquare } from "react-icons/lu";
import { VscDebugRestart } from "react-icons/vsc";
import { HiMiniBellAlert } from "react-icons/hi2";
import { confirm } from "../../../new_components/Confirmation";
import { TbPhoneOff } from "react-icons/tb";
import { getInputValue } from "../../../new_components/InputModal";

export const Instancia = () => {
    const auth = useAuth();

    const dataTableRef = useRef<any>();
    const qrCodeRef = useRef<any>();
    const msgTesteRef = useRef<any>();

    function onAfterConnect() {
        dataTableRef.current.refresh();
    }

    function conectarInstancia(rowData: any) {
        if (rowData.situacao === 1)
            toast.error('Instância já está conectada.');
        else
            qrCodeRef.current.openModal(rowData.id);
    }

    async function post(api: string, _id: any) {
        try {
            const response: any = await auth.requestPost(api, { id: _id });

            toast.success(response.data.mensagem);
            dataTableRef.current.refresh();
        } catch (error: any) {
            toastError(error);
        };
    }

    async function desconectarInstancia(rowData: any) {
        if (rowData.situacao === 0)
            toast.error('Instância já está desconectada.')
        else if (await confirm('Deseja mesmo se desconectar da sessão?'))
            post(DESCONECTAR_INSTANCIA, rowData.id);
    }

    async function reiniciarInstancia(rowData: any) {
        if (rowData.situacao === 0)
            toast.error('Instância está desconectada.')
        else if (await confirm('Deseja mesmo reiniciar a sessão?'))
            post('/instancia/reiniciar', rowData.id);
    }

    async function habilitarNotificacao(rowData: any) {
        if (rowData.situacao === 0)
            toast.error('Instância está desconectada.')
        else if (await confirm('Deseja mesmo habilitar as notificações para esta sessão?'))
            post('/instancia/habilitarNotificacao', rowData.id);
    }

    function enviarMsgTeste(rowData: any) {
        msgTesteRef.current.openModal(rowData.id);
    }

    async function bloquearChamadaApi(body: any) {
        await auth.requestPost('/instancia/bloquearChamada', body)
            .then((response: any) => toast.success(response.data.mensagem))
            .catch((error: any) => toastError(error));
    }

    async function bloquearChamada(rowData: any) {
        if (rowData.situacao === 0) {
            toast.error('Instância está desconectada.');
            return;
        }

        const response: any = await auth.requestGet('/instancia/config?id=' + rowData.id);
        const rejeitarChamada: number = response.data.dados.rejeitarChamada;

        if (await confirm(`Deseja mesmo ${rejeitarChamada === 0 ? 'bloquear' : 'desbloquear'} as ligações para esta sessão?`)) {
            let body: any = {
                id: rowData.id,
                rejeitarChamada: rejeitarChamada === 0 ? 1 : 0,
                textoRejeicao: ''
            }

            if (rejeitarChamada === 0) {
                const textoRejeicao = await getInputValue('Informe a mensagem que será mostrada quando a ligação for rejeitada:');

                if (textoRejeicao) {
                    body.textoRejeicao = textoRejeicao;
                    await bloquearChamadaApi(body);
                }
            } else
                await bloquearChamadaApi(body);
        }
    }

    const functionsGrid: Array<any> = [
        { icon: <IoQrCode />, color: '#3B82F6', onClick: conectarInstancia, tooltip: 'Conectar Instância' },
        { icon: <HiMiniBellAlert />, color: '#3B82F6', onClick: habilitarNotificacao, tooltip: 'Habilitar Notificações' },
        { icon: <LuMessagesSquare />, color: '#3B82F6', onClick: enviarMsgTeste, tooltip: 'Enviar Mensagem de Teste' },
        { icon: <TbPhoneOff />, color: '#F59E0B', onClick: bloquearChamada, tooltip: 'Bloquear/Desbloquear Chamadas' },
        { icon: <VscDebugRestart />, color: '#F59E0B', onClick: reiniciarInstancia, tooltip: 'Reiniciar Instância' },
        { icon: <IoMdPower />, color: '#EF4444', onClick: desconectarInstancia, tooltip: 'Desconectar Instância' },
    ]

    const cols: Array<BsColumns> = [
        { field: 'id', header: 'ID', sortable: true },
        { field: 'descricao', header: 'Descrição', sortable: true },
        { field: 'servidor.descricao', header: 'Servidor', sortable: true },
        { field: 'situacao', header: 'Situação', body: (e: any) => labelSessaoWhatsApp(e.situacao), style: { width: '100px' } },
        { field: 'edit', exportable: false, adicionalFunctions: functionsGrid, style: { minWidth: '4rem', width: '4rem' } }
    ];

    const onFilter = (value: any, filter: string) => {
        return String(value.id).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.descricao).toLowerCase().includes(filter.toLowerCase()) ||
            String(intToSessaoWhatsApp(value.situacao)).toLowerCase().includes(filter.toLowerCase());
    }

    return (
        <>
            <ConsultaPadrao
                ref={dataTableRef}
                sortcolumn={'descricao'}
                sortcolumnorder={1}
                columns={cols}
                model={MODEL_INSTANCIA}
                onFilterRegisters={onFilter}
                disabledEdit={true}
            />
            <QrCode
                ref={qrCodeRef}
                afterConnect={onAfterConnect}
            />
            <MensagemTeste
                ref={msgTesteRef}
            />
        </>
    );
}
