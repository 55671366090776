import { BsColumns, ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { MODEL_TIPO_SERVICO } from "../../../models";
import { EXCLUIR_TIPOSERVICO } from "../../../routes_api";
import { intToAtivoInativo, labelAtivoInativo } from "../../../utils";

export const TipoServico = () => {
  const cols: Array<BsColumns> = [
    { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
    { field: 'descricao', header: 'Descrição', sortable: true },
    { field: 'ativo', header: 'Situação', sortable: false, body: (e) => labelAtivoInativo(e.ativo), style: { width: '100px' } },
    { field: 'edit', exportable: false, style: { minWidth: '8rem', width: '10rem' } }
  ];

  const onFilter = (value: any, filter: string) => {
    return intToAtivoInativo(value.ativo).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.descricao).toLowerCase().includes(filter.toLowerCase());
  }

  return (
    <ConsultaPadrao
      prefixApiDelete={EXCLUIR_TIPOSERVICO}
      sortcolumn={'descricao'}
      sortcolumnorder={1}
      columns={cols}
      model={MODEL_TIPO_SERVICO}
      onFilterRegisters={onFilter}
    />
  );
}
