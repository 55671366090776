export const TitleDataTable = (props: any) => {
    return (
        <div style={{
            paddingTop: '6px',
            paddingLeft: '12px',
            color: 'var(--font-color)',
            fontWeight: 600
        }}>
            {props.title}
        </div >
    )
}