import { Paper, PaperProps, ThemeProvider, useTheme } from "@mui/material";

export const BsPaper = (props: PaperProps) => {
    const theme = useTheme();

    return (
        <ThemeProvider theme={theme}>
            <Paper
                {...props}
                elevation={8}
                sx={{
                    p: '18px',
                    backgroundImage: 'none',
                    backgroundColor: 'var(--background-color)',
                    borderRadius: '8px',
                    boxShadow: `0px 2px 20px rgba(22, 22, 22, 0.1)`
                }}
            />
        </ThemeProvider>
    )
}
