import { GiReceiveMoney } from "react-icons/gi";
import {
    CONSULTA_ASSISTENTE,
    CONSULTA_CLIENTE, CONSULTA_CLIENTESERVICO, CONSULTA_CONTAS_RECEBER, CONSULTA_CONTATO_AVULSO, CONSULTA_DISPAROS, CONSULTA_INSTANCIA, CONSULTA_LISTA_TRANSMISSAO,
    CONSULTA_LOG_ATIVIDADES, CONSULTA_MOV_FINANCEIRO, CONSULTA_RENOVACOES, CONSULTA_REVENDA, CONSULTA_REVENDA_MASTER, CONSULTA_SERVICO, CONSULTA_SUPORTE_MASTER, CONSULTA_TEMPLATEMENSAGEM,
    CONSULTA_TIPOSERVICO, CONSULTA_TIPOS_TEMPLATE, CONSULTA_TREINO, SALVAR_ASSISTENTE, SALVAR_CLIENTE, SALVAR_CLIENTESERVICO, SALVAR_CONTAS_RECEBER, SALVAR_CONTATO_AVULSO, SALVAR_INSTANCIA, SALVAR_LISTA_TRANSMISSAO,
    SALVAR_MASTER, SALVAR_REVENDA, SALVAR_SERVICO, SALVAR_TEMPLATEMENSAGEM, SALVAR_TIPOSERVICO, SALVAR_TREINO, CONSULTA_ATENDIMENTOIA,
    consulta_contas_pagar,
    salvar_contas_pagar
} from "../routes_api";

export const OPTIONS_SIM_NAO: Array<any> = [
    { contador: 0, descricao: 'Não' },
    { contador: 1, descricao: 'Sim' }
]

export const OPTIONS_TIPO_TREINO: Array<any> = [
    { contador: 0, descricao: 'Texto' },
    { contador: 1, descricao: 'URL/Link' }
]

export const OPTIONS_TIPO_ASSITENTE: Array<any> = [
    { contador: 0.3, descricao: 'Mais Criativo' },
    { contador: 0.5, descricao: 'Mais Balanceado' },
    { contador: 1.0, descricao: 'Mais Preciso' }
]

// ----- TIPO SERVIÇO ----- //

export const MODEL_TIPO_SERVICO = {
    apiConsulta: CONSULTA_TIPOSERVICO,
    apiCadastro: SALVAR_TIPOSERVICO,
    base: {
        contador: 0,
        descricao: '',
        ativo: 1
    },
    inputs: [
        { xs: 3, id: 'contador', label: 'Código', type: 'text', disabled: true },
        { xs: 9, id: 'descricao', label: 'Descrição', type: 'text', required: true, maxLength: 100 }
    ],
    module: 'Tipos Serviço',
    title: 'Tipo Serviço'
}

// ----- SERVIÇO ----- //

export const MODEL_SERVICO = {
    apiConsulta: CONSULTA_SERVICO,
    apiCadastro: SALVAR_SERVICO,
    base: {
        contador: 0,
        descricao: '',
        valor: 0,
        ativo: 1,
        custo: 0,
        atualizaValorCliente: 0,
        tipoServico: {
            contador: '',
            descricao: '',
            ativo: 1
        }
    },
    inputs: [
        { xs: 3, id: 'contador', label: 'Código', type: 'text', disabled: true },
        { xs: 9, id: 'descricao', label: 'Descrição', type: 'text', required: true, maxLength: 100 },
        { xs: 12, id: 'tipoServico.contador', label: 'Tipo Serviço', type: 'select', required: true, model: MODEL_TIPO_SERVICO },
        { xs: 4, id: 'custo', label: 'Custo', type: 'number', required: true },
        { xs: 4, id: 'valor', label: 'Valor', type: 'number', required: true },
        { xs: 4, id: 'atualizaValorCliente', label: 'Atualizar Clientes', type: 'select', required: true, options: OPTIONS_SIM_NAO, exclusiveEdit: true, default: 0 }
    ],
    module: 'Serviços',
    title: 'Serviço',
    sizeDialog: 'sm'
};

// ----- CLIENTE ----- //

function changeNotifica(_register: any, refs: any) {
    const ref = refs.current['whatsapp'];
    ref.setRequired(_register.contador === 1);
}

function changeServicoCliente(_register: any, refs: any) {
    var ref = refs.current['valor'];
    ref.setCustomValue(_register.valor);

    refs.current['valor'].setDisabled((_register.contador === '') || (_register.contador === 0));
    refs.current['login'].setDisabled((_register.contador === '') || (_register.contador === 0));
    refs.current['senha'].setDisabled((_register.contador === '') || (_register.contador === 0));
    refs.current['dataExpiracao'].setDisabled((_register.contador === '') || (_register.contador === 0));
}

export const MODEL_CLIENTE = {
    apiConsulta: CONSULTA_CLIENTE,
    apiCadastro: SALVAR_CLIENTE,
    base: {
        ativo: 1,
        bonus: 0,
        contador: 0,
        dataCadastro: '',
        masterContador: 0,
        nome: '',
        notifica: 1,
        observacao: '',
        whatsapp: '',
        whatsappSecundario: '',
        servico: {
            contador: '',
            descricao: ''
        },
        valor: 0,
        login: '',
        senha: '',
        dataExpiracao: ''
    },
    externalInputs: [
        { xs: 12, id: 'servico.contador', label: 'Serviço', type: 'select', onAfterChange: changeServicoCliente, exclusiveNew: true, model: MODEL_SERVICO },
        { xs: 4, id: 'valor', label: 'Valor', type: 'number', disabled: true, required: true, exclusiveNew: true },
        { xs: 4, id: 'login', label: 'Login', type: 'text', maxLength: 50, disabled: true, exclusiveNew: true },
        { xs: 4, id: 'senha', label: 'Senha', type: 'text', maxLength: 30, disabled: true, exclusiveNew: true },
        { xs: 6, id: 'dataExpiracao', label: 'Expiração', type: "datetime-local", disabled: true, required: true, exclusiveNew: true }
    ],
    externalInputsLabel: 'Gestão Serviço',
    inputs: [
        { xs: 2, id: 'contador', label: 'Código', type: 'text', disabled: true },
        { xs: 4, id: 'dataCadastro', label: 'Cadastro', type: 'datetime-local', disabled: true },
        { xs: 6, id: 'nome', label: 'Nome', type: 'text', multiline: true, maxRows: 4, required: true, maxLength: 100 },
        { xs: 2, id: 'notifica', label: 'Notifica', type: 'select', required: true, options: OPTIONS_SIM_NAO, onAfterChange: changeNotifica },
        { xs: 3.75, id: 'whatsapp', label: 'WhatsApp', type: 'text', maxLength: 20, required: true },
        { xs: 3.75, id: 'whatsappSecundario', label: '2º WhatsApp', type: 'text', maxLength: 20, required: false },
        { xs: 2.5, id: 'bonus', label: 'Bônus', type: 'number' },
        { xs: 12, id: 'observacao', label: 'Observação', type: 'text', multiline: true, maxRows: 4, maxLength: 500 }
    ],
    module: 'Clientes',
    title: 'Cliente',
    sizeDialog: 'sm'
}

// ----- GESTÃO SERVICO ----- //

function changeServico(_register: any, refs: any) {
    const ref = refs.current['valor'];
    ref.setCustomValue(_register.valor);
}

export const MODEL_GESTAO_SERVICO = {
    apiConsulta: CONSULTA_CLIENTESERVICO,
    apiCadastro: SALVAR_CLIENTESERVICO,
    base: {
        cliente: {
            contador: ''
        },
        contador: 0,
        dataExpiracao: '',
        login: '',
        observacao: '',
        senha: '',
        servico: {
            contador: ''
        },
        valor: 0,
        ativo: 1
    },
    inputs: [
        { xs: 3, id: 'contador', label: 'Código', type: 'text', disabled: true },
        { xs: 9, id: 'cliente.contador', label: 'Cliente', type: 'select', required: true, disabledEdit: true, model: MODEL_CLIENTE },
        { xs: 9, id: 'servico.contador', label: 'Serviço', type: 'select', required: true, model: MODEL_SERVICO, onAfterChange: changeServico, changeOpened: false },
        { xs: 3, id: 'valor', label: 'Valor', type: 'number', required: true },
        { xs: 4, id: 'login', label: 'Login', type: 'text', maxLength: 50 },
        { xs: 3.5, id: 'senha', label: 'Senha', type: 'text', maxLength: 30 },
        { xs: 4.5, id: 'dataExpiracao', label: 'Expiração', type: "datetime-local", required: true },
        { xs: 12, id: 'observacao', label: 'Observação', type: 'text', multiline: true, maxRows: 4, maxLength: 500 }
    ],
    module: 'Gestão Serviços',
    title: 'Gestão Serviço'
}

// ----- TIPO TEMPLATE ----- //

export const MODEL_TIPO_TEMPLATE = {
    apiConsulta: CONSULTA_TIPOS_TEMPLATE,
    inputs: []
}

// ----- INSTANCIA ----- //

export const MODEL_INSTANCIA = {
    apiConsulta: CONSULTA_INSTANCIA,
    apiCadastro: SALVAR_INSTANCIA,
    base: {
        id: '',
        urlWebhook: '',
        token: '',
        descricao: ''
    },
    inputs: [
        { xs: 12, id: 'descricao', label: 'Descrição', type: 'text', required: true, maxLength: 200 }
    ],
    module: 'Instâncias',
    title: 'Instância'
}

// ----- TEMPLATE MENSAGEM ----- //

const changeTipoMensagem = (_register: any, refs: any) => {
    const refDiasIntervalo = refs.current['diasIntervalo'];
    const refTipoServico = refs.current['tiposServico'];
    refDiasIntervalo.setDisabled((_register.contador === 3) || (_register.contador === 5) || (_register.contador === 6));
    refTipoServico.setDisabled(!((_register.contador === 1) || (_register.contador === 4) || (_register.contador === 3)));
}

export const MODEL_TEMPLATE_MENSAGEM = {
    apiConsulta: CONSULTA_TEMPLATEMENSAGEM,
    apiCadastro: SALVAR_TEMPLATEMENSAGEM,
    base: {
        contador: 0,
        descricao: '',
        diasIntervalo: '',
        texto: '',
        tipo: {
            contador: 1,
            descricao: 'Cobrança Cliente'
        },
        tiposServico: []
    },
    inputs: [
        { xs: 3, id: 'contador', label: 'Código', type: 'text', disabled: true },
        { xs: 9, id: 'descricao', label: 'Descrição', type: 'text', required: true, maxLength: 100 },
        { xs: 12, id: 'texto', label: 'Mensagem', type: 'text', required: true, multiline: true, maxRows: 10, maxLength: 1500 },
        { xs: 4, id: 'tipo.contador', label: 'Tipo', type: 'select', required: true, onAfterChange: changeTipoMensagem, model: MODEL_TIPO_TEMPLATE, disableEdit: true },
        { xs: 8, id: 'tiposServico', label: 'Tipo Serviço', type: 'select', model: MODEL_TIPO_SERVICO, multiple: true },
        { xs: 12, id: 'diasIntervalo', label: 'Dias Intervalo', type: 'number' }
    ],
    module: 'Template Mensagens',
    title: 'Template Mensagem',
    sizeDialog: 'md'
}

// ----- DISPAROS ----- //

export const MODEL_DISPAROS = {
    apiConsulta: CONSULTA_DISPAROS,
    base: {
        contador: 0,
        texto: '',
        masterContador: 0,
        tipo: '',
        whatsapp: '',
        dataCadastro: '',
        dataEnvio: '',
        situacao: 1,
        cliente: {
            contador: '',
            nome: ''
        }
    },
    inputs: [],
    module: 'Disparos',
    title: 'Disparo'
}

// ----- CONTATO AVULSO ----- //

export const MODEL_CONTATO_AVULSO = {
    apiConsulta: CONSULTA_CONTATO_AVULSO,
    apiCadastro: SALVAR_CONTATO_AVULSO,
    base: {
        contador: 0,
        descricao: '',
        whatsapp: '',
        ativo: 1
    },
    inputs: [
        { xs: 3, id: 'contador', label: 'Código', type: 'text', disabled: true },
        { xs: 9, id: 'descricao', label: 'Descrição', type: 'text', required: true, maxLength: 100 },
        { xs: 12, id: 'whatsapp', label: 'WhatsApp', type: 'text', maxLength: 20 }
    ],
    module: 'Contatos Avulsos',
    title: 'Contato Avulso'
}

// ----- LISTA TRANSMISSAO ----- //

export const MODEL_LISTA_TRANSMISSAO = {
    apiConsulta: CONSULTA_LISTA_TRANSMISSAO,
    apiCadastro: SALVAR_LISTA_TRANSMISSAO,
    base: {
        contador: 0,
        descricao: '',
        ativo: 1,
        ativos: 1,
        vencidos: 1,
        clientes: [],
        servicos: [],
        tiposServico: [],
        contatosAvulso: [],
        clientesIgnorar: [],
        tipo: 0
    },
    inputs: [
        { xs: 3, id: 'contador', label: 'Código', type: 'text', disabled: true },
        { xs: 9, id: 'descricao', label: 'Descrição', type: 'text', required: true, maxLength: 100 },
        { xs: 12, id: 'clientes', label: 'Clientes', type: 'select', multiple: true, model: MODEL_CLIENTE },
        { xs: 12, id: 'servicos', label: 'Serviços', type: 'select', multiple: true, model: MODEL_SERVICO },
        { xs: 12, id: 'tiposServico', label: 'Tipos Serviços', type: 'select', multiple: true, model: MODEL_TIPO_SERVICO },
        { xs: 12, id: 'contatosAvulso', label: 'Contatos Avulsos', type: 'select', multiple: true, model: MODEL_CONTATO_AVULSO },
        { xs: 12, id: 'clientesIgnorar', label: 'Clientes Ignorados', type: 'select', multiple: true, model: MODEL_CLIENTE },
        {
            xs: 4, id: 'ativos', label: 'Considera Situação', type: 'select', required: true,
            options: [
                { contador: 0, descricao: 'Inativo' },
                { contador: 1, descricao: 'Ativo' },
                { contador: 2, descricao: 'Todos' }]
        },
        {
            xs: 4, id: 'vencidos', label: 'Considera Expiração', type: 'select', required: true,
            options: [
                { contador: 0, descricao: 'Vencidos' },
                { contador: 1, descricao: 'Em dia' },
                { contador: 2, descricao: 'Todos' }
            ]
        },
        {
            xs: 4, id: 'tipo', label: 'Tipo', type: 'select', required: true, exclusiveNew: true,
            options: [
                { contador: 0, descricao: 'Privada' },
                { contador: 1, descricao: 'Pública' }
            ]
        }
    ],
    module: 'Listas de Transmissão',
    title: 'Lista de Transmissão'
}

// ----- RENOVACOES ----- //

export const MODEL_HISTORICO_RENOVACOES = {
    apiConsulta: CONSULTA_RENOVACOES,
    base: {
        contador: 0,
        bonusUtilizado: 0,
        cliente: {
            contador: '',
            nome: ''
        },
        custo: 0,
        dataAnterior: '',
        dataCadastro: '',
        desconto: 0,
        novaData: '',
        servico: {
            contador: '',
            descricao: ''
        },
        valorRecebido: 0
    },
    inputs: [],
    module: 'Renovações',
    title: 'Renovação'
}

// ----- LOG ATIVIDADE ----- //

export const MODEL_LOG_ATIVIDADE = {
    apiConsulta: CONSULTA_LOG_ATIVIDADES,
    base: {
        contador: 0,
        data: '',
        masterContador: 0,
        texto: '',
        usuario: {
            contador: '',
            nome: ''
        }
    },
    inputs: [],
    module: 'Logs Atividades',
    title: 'Log Atividade'
}

// ----- REVENDA MASTER ----- //

export const MODEL_REVENDA_MASTER = {
    apiConsulta: CONSULTA_REVENDA_MASTER,
    apiCadastro: SALVAR_MASTER,
    base: {
        contador: 0,
        dataExpiracao: '',
        email: '',
        nome: '',
        plano: {
            contador: '',
            descricao: '',
            ativo: 1,
            valor: 0,
        },
        sessao: '',
        sessaoKey: '',
        whatsapp: '',
        ativo: 1,
        privilegio: 1,
        revenda: {
            contador: ''
        },
        logo: ''
    },
    inputs: []
}

// ----- MASTER ----- //

export const MODEL_MASTER = {
    apiConsulta: CONSULTA_REVENDA_MASTER,
    base: {
        contador: 0,
        descricao: ''
    },
    inputs: []
}

// ----- REVENDA ADM ----- //

export const MODEL_REVENDA_ADM = {
    apiConsulta: CONSULTA_REVENDA,
    apiCadastro: SALVAR_REVENDA,
    base: {
        contador: 0,
        dataExpiracao: '',
        email: '',
        privilegio: 2,
        sessao: '',
        sessaoKey: '',
        whatsapp: ''
    },
    inputs: [
        { xs: 3, id: 'contador', label: 'Código', type: 'text', disabled: true },
        { xs: 9, id: 'master.contador', label: 'Master', type: 'select', required: true, model: MODEL_MASTER, disableEdit: true }
    ],
    module: 'Revendas',
    title: 'Revenda'
}

// CONTAS RECEBER

export const MODEL_CONTAS_RECEBER = {
    apiConsulta: CONSULTA_CONTAS_RECEBER,
    apiCadastro: SALVAR_CONTAS_RECEBER,
    base: {
        cliente: {
            contador: '',
            descricao: ''
        },
        contador: 0,
        emissao: '',
        numDocumento: '',
        observacao: '',
        quitacao: '',
        situacao: 0,
        valor: 0,
        valorAberto: 0,
        valorPago: 0,
        vencimento: ''
    },
    inputs: [
        { xs: 3, id: 'contador', label: 'Código', type: 'text', disabled: true },
        { xs: 9, id: 'cliente.contador', label: 'Cliente', type: 'select', required: true, model: MODEL_CLIENTE },
        { xs: 4, id: 'numDocumento', label: 'Número Doc.', type: 'text', maxLength: 50 },
        { xs: 3.5, id: 'valor', label: 'Valor', type: 'number', required: true, isGreaterThanZero: true },
        { xs: 4.5, id: 'vencimento', label: 'Vencimento', type: 'datetime-local', required: true },
        { xs: 12, id: 'observacao', label: 'Observação', type: 'text', multiline: true, maxRows: 4, maxLength: 500 }
    ],
    module: 'Contas Receber',
    title: 'Conta Receber',
    sizeDialog: 'sm'
}

export const model_contas_pagar = {
    apiConsulta: consulta_contas_pagar,
    apiCadastro: salvar_contas_pagar,
    base: {
        fornecedor: '',
        contador: 0,
        emissao: '',
        numDocumento: '',
        observacao: '',
        quitacao: '',
        situacao: 0,
        valor: 0,
        valorAberto: 0,
        valorPago: 0,
        vencimento: ''
    },
    inputs: [
        { xs: 3, id: 'contador', label: 'Código', type: 'text', disabled: true },
        { xs: 9, id: 'fornecedor', label: 'Fornecedor', type: 'text', required: true, maxLength: 50 },
        { xs: 4, id: 'numDocumento', label: 'Número Doc.', type: 'text', maxLength: 50 },
        { xs: 3.5, id: 'valor', label: 'Valor', type: 'number', required: true, isGreaterThanZero: true },
        { xs: 4.5, id: 'vencimento', label: 'Vencimento', type: 'datetime-local', required: true },
        { xs: 12, id: 'observacao', label: 'Observação', type: 'text', multiline: true, maxRows: 4, maxLength: 500 }
    ],
    module: 'Contas Pagar',
    title: 'Conta Pagar',
    sizeDialog: 'sm'
}


// MOVIMENTACAO

export const MODEL_MOV_FINANCEIRO = {
    apiConsulta: CONSULTA_MOV_FINANCEIRO,
    base: {
        contador: 0,
        data: '',
        historico: '',
        usuContador: 0,
        tipo: '',
        valor: 0
    },
    inputs: [],
    module: 'Movimentos Financeiro',
    title: 'Movimento Financeiro'
}

// MASTERS ADM

export const MODEL_SUPORTE_MASTER = {
    apiConsulta: CONSULTA_SUPORTE_MASTER,
    base: {
        contador: 0,
        nome: '',
        token: ''
    },
    inputs: [],
    module: 'Suporte Masters',
    title: 'Suporte Master'
}

// ASSISTENTE

export const MODEL_ASSISTENTE = {
    apiConsulta: CONSULTA_ASSISTENTE,
    apiCadastro: SALVAR_ASSISTENTE,
    base: {
        contador: 0,
        descricao: '',
        instancia: {
            contador: 0
        },
        prompt: '',
        promptSystem: '',
        temperature: 0.5,
        quantidadeResposta: 0,
        publicIdTypeBot: '',
        ativo: 1
    },
    inputs: [
        { xs: 3, id: 'contador', label: 'Código', type: 'text', disabled: true },
        { xs: 9, id: 'descricao', label: 'Descrição', type: 'text', required: true, maxLength: 50 },
        { xs: 12, id: 'instancia.contador', label: 'Instância', type: 'select', required: true, model: MODEL_INSTANCIA },
        { xs: 12, id: 'email', label: 'E-mail', type: 'text', required: true, maxLength: 50 },
        { xs: 12, id: 'publicIdTypeBot', label: 'ID TypeBot', type: 'text', maxLength: 45 }

    ],
    module: 'Assistentes',
    title: 'Assistente'
}

// TREINOS

export const MODEL_TREINO = {
    apiConsulta: CONSULTA_TREINO,
    apiCadastro: SALVAR_TREINO,
    base: {
        contador: 0,
        conteudo: '',
        tipo: 0,
        ativo: 1,
        assistente: {
            contador: 0
        }
    },
    inputs: [
        { xs: 3, id: 'contador', label: 'Código', type: 'text', disabled: true },
        { xs: 9, id: 'tipo', label: 'Tipo', type: 'select', required: true, options: OPTIONS_TIPO_TREINO },
        { xs: 12, id: 'assistente.contador', label: 'Assistente', type: 'select', required: true, model: MODEL_ASSISTENTE },
        { xs: 12, id: 'conteudo', label: 'Conteudo', type: 'text', multiline: true, maxRows: 4, required: true, maxLength: 15000 },
    ],
    module: 'Treinos',
    title: 'Treino'
}

// ATENDIMENTOIA

export const MODEL_ATEMDIMENTOIA = {
    apiConsulta: CONSULTA_ATENDIMENTOIA,
    base: {
        contador: 0,
        assistente: {
            contador: 0,
            descricao: '""',
        },
        dataInicio: '',
        pausadoAte: '',
        atualizado: '',
        quantidade: 0,
        situacao: 0,
        whatsapp: ''


    },
    inputs: [],
    module: 'Atendimentos',
    title: 'Atendimento'
}

function changeValorRecebimento(_newValue: any, refs: any) {
    refs.current['valorAberto'].setValue(refs.current['valor'].getValue() - refs.current['valorRecebido'].getValue() - _newValue)
}

function validateValorRecebimento(_value: any, refs: any) {
    if (_value <= 0)
        return 'Valor Recebimento precisa ser maior que zero';

    if (refs.current['valorAberto'].getValue() < 0)
        return 'Valor Restante precisa ser maior ou igual que zero';
}

export const model_recebimento = {
    icon: <GiReceiveMoney />,
    name: 'Recebimento',
    api_post: '/contasReceber/recebimento',
    inputs: [
        { id: 'contador', label: 'Código', type: 'text', md: 3, disabled: true },
        { id: 'cliente.nome', label: 'Cliente', type: 'text', md: 9, disabled: true },
        { id: 'numDocumento', label: 'Número Documento', type: 'text', md: 4, disabled: true },
        { id: 'emissao', label: 'Emissão', type: 'datetime-local', md: 4, disabled: true },
        { id: 'vencimento', label: 'Vencimento', type: 'datetime-local', md: 4, disabled: true },
        { id: 'valor', label: 'Valor', type: 'monetary', md: 3, disabled: true },
        { id: 'valorReceber', label: 'Valor Recebimento', type: 'monetary', md: 3, onAfterChange: changeValorRecebimento, onBeforeValidate: validateValorRecebimento },
        { id: 'valorRecebido', label: 'Valor Recebido', type: 'monetary', md: 3, disabled: true },
        { id: 'valorAberto', label: 'Valor Restante', type: 'monetary', md: 3, disabled: true },
    ],
    base: {

    }
}

function changeValorPagamento(_newValue: any, refs: any) {
    refs.current['valorAberto'].setValue(refs.current['valor'].getValue() - refs.current['valorPago'].getValue() - _newValue)
}

function validateValorPagamento(_value: any, refs: any) {
    if (_value <= 0)
        return 'Valor Pagamento precisa ser maior que zero';

    if (refs.current['valorAberto'].getValue() < 0)
        return 'Valor Restante precisa ser maior ou igual que zero';
}

export const model_pagamento = {
    icon: <GiReceiveMoney />,
    name: 'Pagamento',
    api_post: '/contasPagar/pagamento',
    inputs: [
        { id: 'contador', label: 'Código', type: 'text', md: 3, disabled: true },
        { id: 'fornecedor', label: 'Fornecedor', type: 'text', md: 9, disabled: true },
        { id: 'numDocumento', label: 'Número Documento', type: 'text', md: 4, disabled: true },
        { id: 'emissao', label: 'Emissão', type: 'datetime-local', md: 4, disabled: true },
        { id: 'vencimento', label: 'Vencimento', type: 'datetime-local', md: 4, disabled: true },
        { id: 'valor', label: 'Valor', type: 'monetary', md: 3, disabled: true },
        { id: 'valorPagar', label: 'Valor Pagamento', type: 'monetary', md: 3, onAfterChange: changeValorPagamento, onBeforeValidate: validateValorPagamento },
        { id: 'valorPago', label: 'Valor Pago', type: 'monetary', md: 3, disabled: true },
        { id: 'valorAberto', label: 'Valor Restante', type: 'monetary', md: 3, disabled: true },
    ],
    base: {

    }
}

export const model_historico_indicacoes = {
    apiConsulta: 'controleIndicacoes',
    base: {
        masterDestino: {
            contador: 0
        },
        masterOrigem: {
            contador: 0
        },
        dataCadastro: '',
        situacao: 0,
    },
    inputs: [],
    module: 'Histórico de Indicações',
    title: 'Histórico Indicação'
}

const model_servidores_safiraway = {
    apiConsulta: 'servidoresSafiraway',
    base: {},
    inputs: [],
    module: 'Servidores SafiraWay',
    title: 'Servidores SafiraWay'
}

export const model_safiraway = {
    apiConsulta: 'safiraway',
    apiCadastro: 'safiraway/salvar',
    base: {
        contador: 0,
        descricao: '',
        ativo: 1,
        dominio: '',
        servidor: {
            contador: 0
        }
    },
    inputs: [
        { xs: 3, id: 'contador', label: 'Código', type: 'text', disabled: true },
        { xs: 9, id: 'descricao', label: 'Descrição', type: 'text', required: true, maxLength: 100 },
        { xs: 12, id: 'dominio', label: 'Domínio', type: 'text', required: true, maxLength: 100 },
        { xs: 12, id: 'servidor', label: 'Servidor', type: 'select', required: true, model: model_servidores_safiraway, disableEdit: true }
    ],
    module: 'SafiraWay (Proxy)',
    title: 'SafiraWay (Proxy)'
}