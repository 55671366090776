import { ConsultaPadrao, BsColumns } from '../../../components/ConsultaPadrao';
import { useContext, useEffect, useRef, useState } from 'react';
import { formatedDateTime, labelAtivoInativo, formatedTelefone, intToAtivoInativo, formatedPrice } from '../../../utils';
import { AuthContext } from '../../../contexts/AuthProvider';
import { CONSULTA_SERVICO_COMPLETO, EXCLUIR_CLIENTE, EXISTE_WHATSAPP } from '../../../routes_api';
import 'primeflex/primeflex.css'
import { MODEL_CLIENTE } from '../../../models';
import { toast } from 'react-toastify';
import { FaWhatsapp } from "react-icons/fa";
import { confirm } from '../../../new_components/Confirmation';

export const Cliente = () => {
  const auth = useContext(AuthContext);

  const dataTableRef = useRef<any>(null);

  const [servico, setServico] = useState<any[]>();

  useEffect(() => {
    async function fetchServicos() {
      const response: any = await auth.requestGet(CONSULTA_SERVICO_COMPLETO);
      setServico(response.data.dados.filter((item: any) => { return item.ativo === 1 }));
    }

    fetchServicos();
  }, [auth]);

  function openWhatsAppWeb(e: any) {
    if (e.whatsapp)
      window.open('https://wa.me/' + e.whatsapp, '_blank');
    else
      toast.warning('WhatsApp não cadastrado.');
  }

  const functionsGrid: Array<any> = [
    { icon: <FaWhatsapp />, color: '#0FC144', onClick: (openWhatsAppWeb) }
  ]

  const cols: Array<BsColumns> = [
    { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
    { field: 'nome', header: 'Nome', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
    { field: 'whatsapp', header: 'WhatsApp', sortable: true, body: (e) => formatedTelefone(e.whatsapp), style: { width: '125px' } },
    { field: 'whatsappSecundario', header: '2º WhatsApp', sortable: true, body: (e) => formatedTelefone(e.whatsappSecundario), style: { width: '125px' } },
    { field: 'dataCadastro', header: 'Cadastro', body: (e: any) => formatedDateTime(e.dataCadastro), style: { width: '125px' } },
    { field: 'notifica', header: 'Notifica', body: (e) => e.notifica === 0 ? 'Não' : 'Sim', style: { width: '75px' } },
    { field: 'bonus', header: 'Bônus', body: (e) => formatedPrice(e.bonus), style: { width: '75px' } },
    { field: 'observacao', header: 'Observação', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
    { field: 'ativo', header: 'Situação', body: (e) => labelAtivoInativo(e.ativo), style: { width: '75px' } },
    { field: 'edit', exportable: false, adicionalFunctions: functionsGrid, style: { minWidth: '4rem', width: '4rem' } }
  ];

  const onFilter = (value: any, filter: string) => {
    const notificaConverted = value.notifica === 0 ? 'Não' : 'Sim';

    return intToAtivoInativo(value.ativo).toLowerCase().includes(filter.toLowerCase()) ||
      formatedDateTime(value.dataCadastro).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
      formatedTelefone(value.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
      notificaConverted.toLowerCase().includes(filter.toLowerCase()) ||
      String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.observacao).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.nome).toLowerCase().includes(filter.toLowerCase());
  }


  function changeServico(_register: any, refs: any) {
    var ref = refs.current['valor'];
    var selectedRegister: any = servico?.filter((item: any) => item.contador === _register);
    if (selectedRegister[0])
      ref.setCustomValue(selectedRegister[0].valor);

    refs.current['valor'].setDisabled((_register === '') || (_register === 0));
    refs.current['login'].setDisabled((_register === '') || (_register === 0));
    refs.current['senha'].setDisabled((_register === '') || (_register === 0));
    refs.current['dataExpiracao'].setDisabled((_register === '') || (_register === 0));
  }

  const externalInputs: Array<any> = [
    { id: 'servico.contador', label: 'Serviço', type: 'select', options: servico, onAfterChange: changeServico, exclusiveNew: true },
    { id: 'valor', label: 'Valor', type: 'number', disabled: true, required: true, isGreaterThanZero: true, exclusiveNew: true },
    { id: 'login', label: 'Login', type: 'text', maxLength: 50, disabled: true, exclusiveNew: true },
    { id: 'senha', label: 'Senha', type: 'text', maxLength: 30, disabled: true, exclusiveNew: true },
    { id: 'dataExpiracao', label: 'Expiração', type: "datetime-local", disabled: true, required: true, exclusiveNew: true }
  ]

  async function onBeforeSave(_data: any, _options: any) {
    async function existsWhatsapp(whatsapp: string) {
      const response: any = await auth.requestGet(EXISTE_WHATSAPP + '?whatsapp=' + whatsapp);
      return response.data.dados.length === 0;
    }

    if ((!_data.contador) && (_data.whatsapp !== '')) {
      const accept = await existsWhatsapp(_data.whatsapp);

      if (!accept) {
        if (await confirm('Whatsapp já está cadastrado em outro cliente, deseja continuar?'))
          _options.accept = true
        else {
          _options.accept = false;
          return;
        }
      }
    }

    _options.accept = true;
  }

  return (
    <ConsultaPadrao
      ref={dataTableRef}
      prefixApiDelete={EXCLUIR_CLIENTE}
      sortcolumn={'nome'}
      sortcolumnorder={1}
      columns={cols}
      onFilterRegisters={onFilter}
      onBeforeSave={onBeforeSave}
      externalInputs={externalInputs}
      externalInputsLabel={'Gestão de Serviço'}
      model={MODEL_CLIENTE}
    />
  );
}
