import React, { useImperativeHandle, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { BsLoadingButton } from "../BsLoadingButton";
import styled from "styled-components";

const DialogTytleStyled = styled(DialogTitle)`
    color: #fff;
    background-color: var(--primary-color);
    height: 45px;
    align-items: center; 
    display: flex;
    justify-content: flex-start; 
`
export const BsDialog = React.forwardRef((props: any, ref: any) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    function openModal() {
        setLoading(false);
        setOpen(true);
    }

    function closeModal() {
        setOpen(false);
    }

    function opened() {
        return open
    }

    function getLoading() {
        return loading
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal,
        setLoading,
        opened,
        getLoading
    }));

    return (
        <Dialog open={props.open || open} fullWidth maxWidth={props?.maxWidth} sx={{ ...props.sx }}>
            <DialogTytleStyled fontSize={16}>{props.title}</DialogTytleStyled>
            <DialogContent sx={{ paddingTop: '10px !important' }}>{props.content}</DialogContent>
            {
                !props.invisibleActions &&
                <>
                    <Divider variant="middle" />
                    <DialogActions sx={{ paddingRight: 2, height: '65px' }}>
                        <BsLoadingButton
                            loading={loading}
                            onClick={props?.cancelClick}
                            variant='outlined'
                        >
                            {props.labelCancel ? props.labelCancel : 'Cancelar'}
                        </BsLoadingButton>
                        {props.confirmClick &&
                            <BsLoadingButton
                                loading={loading}
                                onClick={props?.confirmClick}
                                style={{ backgroundColor: props.alert ? 'var(--error-color)' : 'var(--primary-color)' }}
                            >
                                {props.labelConfirm ? props.labelConfirm : 'Salvar'}
                            </BsLoadingButton>
                        }
                    </DialogActions>
                </>
            }
        </Dialog>
    )
})

