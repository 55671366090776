import React, { useEffect, useImperativeHandle, useState } from "react";
import { BsBaseField } from "../BsBaseField"
import moment from "moment-timezone";

export const Inputs = React.forwardRef((props: any, ref) => {
    const [input, setInput] = useState(props.inp);
    const [firstRenderization, setFirstRenderization] = useState(true);
    const [loading, setLoading] = useState(false);
    const [validations, setValidations] = useState({
        validated: true,
        message: ''
    });

    const getOptionInputSelect = (_register: any) => {
        if (input.type === 'select') {
            var value: any;

            if ((Array.isArray(_register[props.inp.id])) || (input.id.split(".")[1] === 'contador'))
                value = _register[input.id.split(".")[0]]
            else
                value = input.options?.find((option: any) => option.contador === _register[props.inp.id])

            if ((value === undefined) || (value.contador === ''))
                return undefined
            else
                return value;
        }
        else
            return _register[input.id] !== undefined ? _register[input.id] : input.default !== undefined ? input.default : ''
    }

    const [value, setValue] = useState<any>(
        input.default ? input.default :
            props.register
                ? props.inp.type === "select"
                    ? getOptionInputSelect(props.register)
                    : props.register[props.inp.id]
                : props.inp.multiple
                    ? []
                    : ""
    );

    useEffect(() => {
        if (input.onAfterChange && value !== undefined)
            if ((input.changeOpened === undefined) || (input.changeOpened === true) || (!firstRenderization))
                input.onAfterChange(value)

        if (firstRenderization)
            setFirstRenderization(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    function clearValue() {
        if (input.multiple)
            setValue([])
        else
            setValue('')
    }

    function getValue() {
        if ((input.type === 'datetime-local') || (input.type === 'date')) {
            const data = moment(new Date(value));
            let dataFormatada: any;

            if (input.type === 'date')
                dataFormatada = data.tz('America/Sao_Paulo').format('YYYY-MM-DD')
            else
                dataFormatada = data.tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm');

            return dataFormatada
        }
        else
            return value;
    }

    function setCustomValue(value: any) {
        setValue(value)
    }

    function getInput() {
        return input;
    }

    function setRequired(value: boolean) {
        let _inp = { ...input };
        _inp.required = value
        setInput(_inp);
    }

    function setDisabled(value: boolean) {
        let _inp = { ...input };
        _inp.disabled = value

        setInput(_inp);

        if (value) {
            if ((_inp.type === 'select') && (_inp.multiple))
                setValue([])
            else
                setValue('')
        }
    }

    function validate() {
        if (input.onValidate) {
            const _validations = input.onValidate(value);

            setValidations(_validations);

            if (!_validations.validated)
                return false;
        }

        if ((!input.required) || (input.disabled))
            return true;

        switch (input.type) {
            case 'text': return value;
            case 'number': return ((value !== '') && (value !== undefined) && ((input.isGreaterThanZero === undefined) || (Number(value) > 0)));
            case 'select': return value;
            case 'datetime-local': return value;
        }
    }

    useImperativeHandle(ref, () => ({
        getValue,
        getInput,
        setRequired,
        setDisabled,
        clearValue,
        setCustomValue,
        setLoading,
        validate,
        setValidations
    }));

    const onChange = (e: any, newValue: any) => {
        var _value: any;

        if ((input.type === 'datetime-local') || (input.type === 'date'))
            _value = e
        else if ((newValue?.contador !== undefined) || Array.isArray(newValue))
            _value = newValue
        else
            _value = e.target.value

        setValue(_value);

        if ((props.submitted) && (input.onValidate)) {
            const _validations = input.onValidate(_value);

            setValidations(_validations);
        }
    }

    return (
        <BsBaseField
            id={input.id}
            label={input.label}
            type={input.type}
            disabled={loading || input.disabled || (input.disabledEdit && Boolean(props.register?.contador))}
            required={input.required}
            multiline={input.multiline}
            maxRows={input.maxRows}
            maxLength={input.maxLength}
            isGreaterThanZero={input.isGreaterThanZero}
            submitted={props.submitted}
            options={input.options}
            multiple={input.multiple}
            value={props.customValue ? props.customValue : value !== undefined ? value : ''}
            size={input.size}
            onChange={(e: any, newValue: any) => onChange(e, newValue)}
            model={input.model}
            showInativos={input.showInativos}
            disableEdit={input.disableEdit}
            filterModel={input.filterModel}
            maxValue={input.maxValue}
            clearValue={() => clearValue()}
            validate={validations.validated}
            messageValidate={validations.message}
        />
    )
})