import { FormControl, IconButton, Typography } from "@mui/material"
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DemoItem } from "@mui/x-date-pickers/internals/demo"
import { ptBR } from '@mui/x-date-pickers/locales';
import { FormHelperTextStyled } from "..";
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import 'dayjs/locale/pt-br';
import dayjs from "dayjs";
import { isMobileDevice } from "../../../utils";

export const BsDateTimeField = (props: any) => {
    function Required() {
        return props.submitted && ((props.required && !props.disabled && (props.value === '' || props.value === undefined || props.value === null)) || (props.error === true))
    }

    return (
        <FormControl fullWidth>
            <LocalizationProvider
                adapterLocale={'pt-br'}
                localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                dateAdapter={AdapterDayjs} >
                <DemoItem>
                    <DateTimePicker
                        label={props.label}
                        value={dayjs(props.value)}
                        onChange={(e) => props.onChange(e)}
                        disabled={props.disabled}
                        format={props.type === 'date' ? "DD/MM/YYYY" : "DD/MM/YYYY HH:mm"}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                        }}
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: Required(),
                                InputProps: {
                                    style: { fontSize: 14 },
                                    required: props.required,
                                    sx: {
                                        "&.Mui-error .MuiSvgIcon-root": {
                                            color: 'red'
                                        }
                                    },
                                    ...(isMobileDevice() &&
                                    {
                                        endAdornment: (
                                            <IconButton size='small' disabled={props.disabled}>
                                                <InsertInvitationOutlinedIcon sx={{ width: 24, height: 24 }} />
                                            </IconButton>
                                        )
                                    })
                                },
                                InputLabelProps: {
                                    style: { fontSize: 13 },
                                    shrink: true,
                                    required: props.required,
                                    error: Required()
                                }
                            },
                        }}
                    />

                </DemoItem>
            </LocalizationProvider>
            <FormHelperTextStyled sx={{ ml: 0, mt: 0, mb: 0 }} >
                <Typography
                    variant='caption'
                    sx={{ alignSelf: 'flex-start', fontSize: 11 }}
                    color={'error'}
                >
                    {(props.submitted && Required()) && 'Campo obrigatório.'}
                </Typography>

            </FormHelperTextStyled>
        </FormControl>
    )
}