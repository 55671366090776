import React, { useState, useImperativeHandle, useRef } from "react"
import { BsInformationModal } from "../../../components/BsInformationModal"
import { BsDialog } from "../../../components/BsDialog";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { RENOVACAO_EXTERNA } from "../../../routes_api";
import { toast } from 'react-toastify';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import copy from "copy-to-clipboard";
import styled from "styled-components";
import { Inputs } from "../../../components/Inputs";

const BoxShowQrCode = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
`
const ImgQrCode = styled.img`
    max-height: 250px;
`
export const RenovacaoPainel = React.forwardRef((props: any, ref) => {
    const auth = useAuth();

    const dialogRef = useRef<any>();
    const mesesRef = useRef<any>();
    const valorRef = useRef<any>();

    const [data, setData] = useState<any>();

    const [openQrCode, setOpenQrCode] = useState(false);
    const [respostaRenovacao, setRespostaRenovacao] = useState<any>();

    function setLoading(value: boolean) {
        dialogRef.current.setLoading(value);
        mesesRef.current.setLoading(value);
        valorRef.current.setLoading(value);
    }

    function closeModal() {
        setLoading(false);
        setRespostaRenovacao(null);
        dialogRef.current.closeModal();
    };

    function openModal(_data: any) {
        setData(_data);
        dialogRef.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal
    }));

    function salvarClick(e: any) {
        async function renovar() {
            setLoading(true);

            try {
                const response: any = await auth.requestPost(RENOVACAO_EXTERNA, {
                    contador: data.master,
                    usuarioContador: data.usuario,
                    quantidade: mesesRef?.current?.getValue(),
                    tipo: 0
                });

                setRespostaRenovacao(response.data.dados);
                valorRef.current.setDisabled(true);
                setOpenQrCode(true);
                toast.success(response.data.mensagem);
                setLoading(false);
            } catch (error: any) {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');;
                setLoading(false);
            }
        }

        if (mesesRef.current.validate())
            renovar();
    }

    function copiaColaClick() {
        copy(respostaRenovacao?.pix.copiaCola);
        toast.success('Pix copiado com sucesso.');
    }

    function changeQtde(_register: any) {
        valorRef.current.setCustomValue((_register * data?.plano).toFixed(2));
    }

    return (
        <>
            <BsDialog
                ref={dialogRef}
                title={'Renovação Painel'}
                maxWidth={'xs'}
                labelCancel={'Sair'}
                labelConfirm={respostaRenovacao ? 'Visualizar QrCode' : 'Salvar'}
                cancelClick={() => closeModal()}
                confirmClick={(e: any) => { respostaRenovacao ? setOpenQrCode(true) : salvarClick(e) }}
                content={
                    <Grid container spacing={2} sx={{ mt: 0 }} >
                        <Grid item xs={12}>
                            <Inputs
                                ref={mesesRef} inp={{ id: 'meses', label: 'Quantidade Meses', type: 'number', default: 1, onAfterChange: changeQtde }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Inputs
                                ref={valorRef} inp={{ id: 'valor', label: 'Valor', type: 'number', default: data?.plano, disabled: true }}
                            />
                        </Grid>
                    </Grid>
                }
            />

            <BsInformationModal
                open={openQrCode}
                title={'Pix: ' + respostaRenovacao?.pix.descricao}
                maxWidth={'xs'}
                children={
                    <BoxShowQrCode >
                        <ImgQrCode alt='' src={'data:image/png;base64,' + respostaRenovacao?.pix.qrCode} />
                        <div>
                            <Typography variant="button" color={'var(--primary-color)'}> {'PIX COPIA E COLA'} </Typography>
                            <IconButton sx={{ color: 'var(--primary-color)', border: '1px solid var(--primary-color)', ml: '7px' }} onClick={() => { copiaColaClick() }}>
                                <ContentCopyOutlinedIcon />
                            </IconButton>
                        </div>
                    </BoxShowQrCode >
                }
                close={() => setOpenQrCode(false)}
            />
        </>
    )
})