import { Button } from "primereact/button"
import { isMobileDevice } from "../../../../utils"

export const ButtonHeader = (props: any) => {
    const buttonStyle = {
        justifyContent: 'center',
        backgroundColor: 'var(--primary-color)',
        color: '#fff',
        marginLeft: props.marginLeft,
        height: props.height ? props.height : '35px',
        width: `${(!isMobileDevice() || props.ignoraMobVersion) ? (props.width ? props.width : 'auto') : '35px'}`,
        minWidth: 0,
        whiteSpace: 'nowrap',
        ...props.style
    };

    return (
        props.visible &&
        <Button
            icon={props.icon}
            label={(!isMobileDevice() || props.ignoraMobVersion) ? props.label : ""}
            className='p-button-sm'
            onClick={props.onClick}
            children={props.children}
            style={{ ...buttonStyle, borderRadius: '8px' }}
        />
    );
}
