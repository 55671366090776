import { FormControlLabel, Grid, Typography, useTheme } from '@mui/material';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../contexts/AuthProvider';
import { CLIENTES_ATIVOS, DISPAROS_REALIZADOS, NOTIFICAR_CLIENTE, NOVOS_CLIENTES, RESULTADO_BRUTO, SERVICOS_UTILIZADOS, TIPOSSERVICOS_UTILIZADOS, VERIFICA_STATUS } from '../../routes_api';
import { formatedDateTime, formatedPrice, formatedTelefone, getApproximatedDate, intToSessaoWhatsApp, isMobileDevice } from '../../utils';
import { BsSwitch } from '../../components/BsSwitch';
import BsPieChart from '../../components/BsPieChart';
import ContainerChart from '../../components/BsPieChart/ContainerChart';
import { BsCardInfo } from '../../components/BsCardInfo';
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import SendIcon from '@mui/icons-material/Send';
import TuneIcon from '@mui/icons-material/Tune';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import { BsCurrencyDollar } from "react-icons/bs";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoInformation } from "react-icons/io5";
import { IoWarningOutline } from "react-icons/io5";
import { AiOutlineAlert } from "react-icons/ai";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DashboardIcon from '@mui/icons-material/Dashboard';
import styled from 'styled-components';
import { ViewNotificacao } from '../Outras/Notificacoes';
import BarChart from '../../charts/bar';
import MixedChart from '../../charts/mixed';
import { DataTableChart } from '../../charts/datatable';
import moment from 'moment';
import { RenovarCliente } from '../Comuns/Renovar';
import { MdAutorenew } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { Notificar } from '../Comuns/Notificar';
import { toast } from 'react-toastify';

const Container = styled.div`
  max-height: calc(100vh - 4.5rem);
  overflow: auto;
  padding: 8px;
  background-color: var(--bg-color-secondary);

  ::-webkit-scrollbar {
    width: 9px; 
  }

  ::-webkit-scrollbar-thumb {
    background: #9c9c9c;
    border-radius: 9px;
  }
`;

const GridContainer = (props: any) => {
  return (
    <Grid container spacing={2} style={{ ...props.style, padding: '10px' }}  >
      {props.children}
    </Grid>
  )
}

const GridCard = (props: any) => {
  return (
    <Grid item xs={isMobileDevice() ? 12 : 3}>
      {props.children}
    </Grid>
  )
}

const ButtonNotificacao = styled.div`
  cursor: pointer;
  border-radius: 16px;


  &:hover {
    margin: -1px;
    border: 1px solid white;
  }
`

const Notificacao = (props: any) => {
  const refViewNotificacao = useRef<any>();

  return (
    <>
      <GridCard>
        <ButtonNotificacao onClick={() => refViewNotificacao.current.openModal()}>
          <BsCardInfo
            value={props.notificacao.titulo}
            title={props.notificacao.tipo === 0 ? 'INFORMAÇÃO' : props.notificacao.tipo === 1 ? 'INFORMAÇÃO IMPORTANTE' : 'ATENÇÃO!'}
            footer={getApproximatedDate(props.notificacao.data)}
            fontSize={'16px'}
            icon={props.notificacao.tipo === 0 ? <IoInformation /> : props.notificacao.tipo === 1 ? <IoWarningOutline /> : <AiOutlineAlert />}
            customColor={props.notificacao.tipo === 0 ? '#64b6f9' : props.notificacao.tipo === 1 ? 'var(--warning-color)' : 'var(--error-color)'}
          />
        </ButtonNotificacao>
      </GridCard >

      <ViewNotificacao ref={refViewNotificacao} notificacao={props.notificacao} onAfterView={() => props.onClickNotificacao()} />
    </>
  )
}

interface TitleGroupProps {
  title: any;
  icon: any;
}

const TitleGroup: React.FC<TitleGroupProps> = ({ title, icon }) => {
  const theme = useTheme();

  return (
    <Grid item xs={12}>
      <div style={{ fontSize: '18px', fontWeight: 600, marginBottom: '-10px', marginTop: '10px', display: 'flex', userSelect: 'none', color: theme.palette.mode === 'dark' ? '#ececec' : 'var(--primary-color)' }}>
        {icon}{title}
      </div>
    </Grid>
  );
}

interface CardProps {
  title: any;
  subtitle?: any;
  icon: any;
}

const Card = forwardRef<any, CardProps>(({ title, subtitle, icon }, ref) => {
  return (
    <GridCard>
      <BsCardInfo ref={ref} title={title} subtitle={subtitle} icon={icon} />
    </GridCard>
  );
});

export const Dashboard = () => {
  const auth = useContext(AuthContext);

  const chartServUtilizadosRef = useRef<any>();
  const chartTipoServUtilizadosRef = useRef<any>();
  const chartNovosClientesRef = useRef<any>();
  const chartResultadosGeraisRef = useRef<any>();
  const chartDisparosRealizadosRef = useRef<any>();
  const cardClientesAtivosRef = useRef<any>();
  const cardValorAbertoRef = useRef<any>();
  const cardSessaoWhatsAppRef = useRef<any>();
  const perspectivaRef = useRef<any>();
  const table7DiasRef = useRef<any>();

  const renovaRef = useRef<any>();
  const notificaRef = useRef<any>();

  const [escondeDados, setEscondeDados] = useState(true);
  const [notificacoes, setNotificacoes] = useState<any[]>([]);

  useEffect(() => {
    async function fetchSituacaoSessao() {
      const response: any = await auth.requestGet(VERIFICA_STATUS);

      cardSessaoWhatsAppRef?.current.setCustomColor(response.data.dados.status < 1 ? 'var(--error-color)' : 'var(--ok-color)')
      cardSessaoWhatsAppRef?.current.setValue(intToSessaoWhatsApp(response.data.dados.status));
    }

    async function getNotificacoes() {
      await auth.requestGet('/notificacoes')
        .then((response: any) => {
          setNotificacoes(response.data.dados.filter((notificacao: any) => notificacao.visualizado === 0));
        })
        .catch((error: any) => {
          console.log(error)
        })
    }

    fetchSituacaoSessao();
    getNotificacoes();
  }, [auth]);

  async function fetchClientesVencendo7Dias() {
    const response: any = await auth.requestGet('/clientesServicos?dataExpiracaoFim=' + moment().add(7, 'days').format('YYYY-MM-DD'));
    table7DiasRef.current.setData(response.data.dados);
  }

  const changeEscondeDados = (e: any) => {
    async function fetchServicosUtilizados() {
      const response: any = await auth.requestGet(SERVICOS_UTILIZADOS);
      const valores = response.data.dados.map((item: any) => item.valor);
      const descricoes = response.data.dados.map((item: any) => item.descricao);

      chartServUtilizadosRef?.current.setLabels(descricoes);
      chartServUtilizadosRef?.current.setSeries(valores);
    }

    async function fetchTiposServicosUtilizados() {
      const response: any = await auth.requestGet(TIPOSSERVICOS_UTILIZADOS);
      const valores = response.data.dados.map((item: any) => item.valor);
      const descricoes = response.data.dados.map((item: any) => item.descricao);

      chartTipoServUtilizadosRef?.current.setLabels(descricoes);
      chartTipoServUtilizadosRef?.current.setSeries(valores);
    }

    async function fetchNovosClientes() {
      const response: any = await auth.requestGet(NOVOS_CLIENTES);
      const valores = response.data.dados.map((item: any) => item.valor);
      const descricoes = response.data.dados.map((item: any) => item.descricao);

      chartNovosClientesRef?.current.setOptions(descricoes, Math.max(Math.max(...valores), 0) + 5);
      chartNovosClientesRef?.current.setSeries(
        [{ name: 'Novos Clientes', data: valores }]
      );
    }

    async function fetchValoresGerais() {
      const response: any = await auth.requestGet(RESULTADO_BRUTO);
      const meses = response.data.dados.map((item: any) => item.descricao);

      const receitas = response.data.dados.map((item: any) => item.receita.toFixed(2));
      const custos = response.data.dados.map((item: any) => item.custo.toFixed(2));
      const lucros = response.data.dados.map((item: any) => item.lucro.toFixed(2));

      if ((lucros.length > 0) || (receitas.length > 0) || (custos.length > 0)) {
        chartResultadosGeraisRef?.current.setOptions(meses, Math.max(Math.max(...receitas, ...custos, ...lucros, 0) + 50), [0], [1, 0.75, 0.75], [4, 2, 2]);
        chartResultadosGeraisRef?.current.setSeries(
          [{ name: 'Lucros', data: lucros, type: 'line' },
          { name: 'Receitas', data: receitas, type: 'column' },
          { name: 'Custos', data: custos, type: 'column' }]
        )
      }
    }

    async function fetchDisparosRealizados() {
      const response: any = await auth.requestGet(DISPAROS_REALIZADOS);
      const valores = response.data.dados.map((item: any) => item.valor);
      const descricoes = response.data.dados.map((item: any) => item.descricao);

      chartDisparosRealizadosRef?.current.setOptions(descricoes, Math.max(Math.max(...valores), 0) + 5);
      chartDisparosRealizadosRef?.current.setSeries(
        [{ name: 'Disparos Realizados', data: valores }]
      );
    }

    async function fetchClientes() {
      const response: any = await auth.requestGet(CLIENTES_ATIVOS);

      cardClientesAtivosRef?.current.setValue(response.data.dados[0].valor);
      cardValorAbertoRef?.current.setValue(formatedPrice(response.data.dados[2].valor));
      perspectivaRef.current.setValue(formatedPrice(response.data.dados[2].valor + response.data.dados[3].valor))
    }

    if (e.target.checked) {
      fetchServicosUtilizados();
      fetchTiposServicosUtilizados();
      fetchNovosClientes();
      fetchValoresGerais();
      fetchDisparosRealizados();
      fetchClientes();
      fetchClientesVencendo7Dias();
    } else {
      chartServUtilizadosRef?.current.setLabels([]);
      chartServUtilizadosRef?.current.setSeries([]);

      chartTipoServUtilizadosRef?.current.setLabels([]);
      chartTipoServUtilizadosRef?.current.setSeries([]);

      chartNovosClientesRef?.current.setOptions([], 0);
      chartNovosClientesRef?.current.setSeries([]);

      chartResultadosGeraisRef?.current.setOptions([], 0);
      chartResultadosGeraisRef?.current.setSeries([]);

      chartDisparosRealizadosRef?.current.setOptions([], 0);
      chartDisparosRealizadosRef?.current.setSeries([]);

      table7DiasRef?.current.setData([]);

      cardClientesAtivosRef?.current.setValue('-----');
      cardValorAbertoRef?.current.setValue('-----');
      perspectivaRef?.current.setValue('-----');
    }

    setEscondeDados(!escondeDados);
  }

  function viewNotificacao(_notficacao: any) {
    setNotificacoes(notificacoes.filter((e) => e.contador !== _notficacao.contador));
  }

  async function renovarClick(row: any) {
    renovaRef.current.openModal(row.contador,
      {
        nome: row.cliente.nome,
        codigo: row.cliente.contador,
        bonus: row.cliente.bonus,
        expiracao: row.dataExpiracao
      },
      {
        descricao: row.servico.descricao,
        valor: row.valor
      }
    );
  }

  async function notificarClick(row: any) {
    if (row.cliente.notifica === 1)
      notificaRef.current.openModal(row.contador, NOTIFICAR_CLIENTE, row.cliente.nome, 'Cliente');
    else
      toast.warning('Recurso disponível apenas para clientes com "Notifica" igual a "Sim".');
  }

  const functionsGrid: Array<any> = [
    { icon: <MdAutorenew />, color: '#2563EB', onClick: renovarClick },
    { icon: <FaWhatsapp />, color: '#10C245', onClick: notificarClick }
  ]

  const columns = [
    { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
    { field: 'cliente.nome', header: 'Cliente', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
    { field: 'cliente.whatsapp', header: 'WhatsApp', sortable: true, body: (e: any) => formatedTelefone(e.cliente.whatsapp), style: { width: '125px' } },
    { field: 'servico.descricao', header: 'Serviço', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
    { field: 'valor', header: 'Valor', sortable: true, body: (e: any) => formatedPrice(e.valor), style: { width: '125px' } },
    { field: 'dataExpiracao', header: 'Data Expiração', sortable: true, body: (e: any) => formatedDateTime(e.dataExpiracao), style: { width: '125px' } },
    { field: 'edit', exportable: false, adicionalFunctions: functionsGrid, style: { minWidth: '3rem', width: '3rem' } }
  ];

  return (
    <Container>
      <GridContainer>
        <Grid item xs={12}>
          <FormControlLabel control={<BsSwitch sx={{ ml: 1.5, mr: 1, mt: 0, mb: 0 }} onChange={changeEscondeDados} />} label={<Typography variant="body2">Mostrar Valores</Typography>} />
        </Grid>

        {notificacoes.length > 0 &&
          <>
            <TitleGroup icon={<NotificationsActiveIcon style={{ marginRight: '5px' }} />} title={'Notificações'} />

            <Grid item xs={12}>
              <Grid container spacing={2}>
                {notificacoes.map((notificacao: any, i) => {
                  return (
                    <Notificacao key={i} notificacao={notificacao} onClickNotificacao={() => viewNotificacao(notificacao)} />
                  )
                })}
              </Grid>
            </Grid>
          </>
        }

        <TitleGroup icon={<DashboardIcon style={{ marginRight: '5px' }} />} title={'Painel'} />

        <Card ref={cardClientesAtivosRef} title='Clientes Ativos' icon={<BsFillPersonCheckFill />} />
        <Card ref={cardValorAbertoRef} title='Valor em Aberto' subtitle='(Mês Atual)' icon={<BsCurrencyDollar />} />
        <Card ref={perspectivaRef} title='Perspectiva' subtitle='(Mês Atual)' icon={<BsCurrencyDollar />} />
        <Card ref={cardSessaoWhatsAppRef} title='Instância WhatsApp' icon={<FaWhatsapp />} />

        <Grid item xs={12}>
          <DataTableChart ref={table7DiasRef} icon={<FaRegCalendarAlt />} title='Vencendo em 7 dias' columns={columns} />
        </Grid>

        <ContainerChart title={'Novos Clientes'} icon={<PersonAddAlt1Icon />}>
          <BarChart ref={chartNovosClientesRef} />
        </ContainerChart>

        <ContainerChart title={'Disparos Realizados'} subtitle={'Últimos 12 meses'} icon={<SendIcon />}>
          <BarChart ref={chartDisparosRealizadosRef} />
        </ContainerChart>

        <ContainerChart title={'Resultados Gerais'} subtitle={'Últimos 12 meses'} icon={<BarChartIcon />} xs={12}>
          <MixedChart ref={chartResultadosGeraisRef} />
        </ContainerChart>

        <ContainerChart title={'Serviços Utilizados'} icon={<TuneIcon />}>
          <BsPieChart ref={chartServUtilizadosRef} />
        </ContainerChart>

        <ContainerChart title={'Tipos Serviços Utilizados'} icon={<DisplaySettingsIcon />}>
          <BsPieChart ref={chartTipoServUtilizadosRef} />
        </ContainerChart>

      </GridContainer>

      <RenovarCliente ref={renovaRef} onFinish={() => fetchClientesVencendo7Dias()} />
      <Notificar ref={notificaRef} />

    </Container >
  );
}