import ReactDOM from 'react-dom/client';
import { Modal } from '../Modal';
import { Input } from '../Input';
import { useRef, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Form } from 'react-bootstrap';

export function getInputValue(title: string): Promise<string | false> {
    return new Promise((resolve) => {
        const container = document.createElement('div');
        document.body.appendChild(container);
        const root = ReactDOM.createRoot(container);

        const ModalWrapper = () => {
            const [validated, setValidated] = useState<boolean>(false);
            const inputref = useRef<any>();

            const handleConfirm = () => {
                setValidated(true);
                const value = inputref.current.getValue();

                if (value) {
                    resolve(inputref.current.getValue());
                    closeModal();
                }
            };

            const handleCancel = () => {
                resolve(false);
                closeModal();
            };

            const closeModal = () => {
                root.unmount();
                document.body.removeChild(container);
            };

            return (
                <Modal
                    open={true}
                    icon={<InfoOutlinedIcon />}
                    title={'Atenção'}
                    onHide={handleCancel}
                    confirmClick={handleConfirm}
                    cancelClick={handleCancel}
                >
                    <Form noValidate validated={validated}>
                        <fieldset>
                            <Input ref={inputref} id="inputmodal" label={title} type="text" required />
                        </fieldset >
                    </Form>
                </Modal>
            );
        };

        root.render(<ModalWrapper />);
    });
}
