import { Grid } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { BsLoadingButton } from "../../../components/BsLoadingButton";
import { toast } from 'react-toastify';
import { CONSULTA_PARAMETROS, SALVAR_PARAMETROS } from "../../../routes_api";
import { Inputs } from "../../../components/Inputs";
import { OPTIONS_SIM_NAO } from "../../../models";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { isMobileDevice, toastCustomError } from "../../../utils";
import { BsCircularLoading } from "../../../components/BsCircularLoading";

export const Parametros = (props: any) => {
    const HORARIOS = Array.from({ length: 16 }, (_, index) => index + 7);

    const auth = useAuth();

    const itemEls = useRef<{ [key: string]: any }>({});
    const btSalvarRef = useRef<any>();

    const [parametros, setParametros] = useState<any>();

    function setLoading(value: boolean) {
        btSalvarRef.current?.setLoading(value);

        if (itemEls)
            Object.values(itemEls.current).forEach((elementRef: any) => { elementRef?.setLoading(value) });
    }


    useEffect(() => {
        async function fetchData() {
            await auth.requestGet(CONSULTA_PARAMETROS).then((response: any) => {
                setParametros(response.data.dados[0]);
            }).catch((error: any) => {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');;
            }).finally(() => setLoading(false));
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const salvarParametros = () => {
        async function salvar() {
            setLoading(true);

            try {
                var _parametros: any = {
                    ...parametros,
                    horarioDisparo: itemEls.current['horarioDisparo'].getValue().contador,
                    dataAtualRenovacao: itemEls.current['dataAtualRenovacao'].getValue().contador,
                    desconsiderarCobranca: itemEls.current['desconsiderarCobranca'].getValue().contador,
                    acessTokenMP: itemEls.current['acessTokenMP'].getValue(),
                    cashback: itemEls.current['cashback'].getValue(),
                    multIntEnvio: itemEls.current['multIntEnvio'].getValue(),
                    maximoEnvio: itemEls.current['maximoEnvio'].getValue(),
                    pausaEnvio: itemEls.current['pausaEnvio'].getValue(),
                };

                const response: any = await auth.requestPost(SALVAR_PARAMETROS, _parametros);

                toast.success(response.data.mensagem);
                setLoading(false);
            } catch (error: any) {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');;
                setLoading(false);
            }
        }

        if (itemEls.current['cashback'] && (itemEls.current['cashback'].getValue() > 100)) {
            toastCustomError('Valor do campo "% Cashback" deve ser menor ou igual a 100.')
            return
        }

        if (itemEls.current['horarioDisparo'].validate() && itemEls.current['dataAtualRenovacao'].validate() && itemEls.current['desconsiderarCobranca'].validate())
            salvar();
    }

    const inputs = [
        {
            id: 'horarioDisparo', label: 'Horário Disparo Notificação', type: 'select', required: true,
            options: HORARIOS.map((hora: number) => (
                { contador: hora, descricao: (hora < 10 ? `0${hora}:00` : `${hora}:00`) }
            )),
            disableEdit: true, xs: 2
        },
        { id: 'dataAtualRenovacao', label: 'Considera Data Atual Renovação', type: 'select', required: true, options: OPTIONS_SIM_NAO, disableEdt: true, xs: 1.5 },
        {
            id: 'desconsiderarCobranca', label: 'Desconsidera Cobrança', type: 'select', required: true,
            options: [
                { contador: 1, descricao: 'Nenhum' },
                { contador: 2, descricao: 'Domingos' },
                { contador: 3, descricao: 'Sábados e Domingos' },
                { contador: 4, descricao: 'Feriados' },
                { contador: 5, descricao: 'Domingos e Feriados' },
                { contador: 6, descricao: 'Sábados, Domingos e Feriados' }
            ],
            xs: 2
        },
        { id: 'acessTokenMP', label: 'Token Mercado Pago', type: 'text', xs: 6, maxLength: 100 },
        { id: 'cashback', label: '% Cashback', type: 'number', xs: 2, maxLength: 2 },
        { id: 'break1', type: 'break', xs: 12 },
        { id: 'multIntEnvio', label: 'Fator de Intervalo', type: 'number', xs: 2 },
        { id: 'maximoEnvio', label: 'Máximo de Envios Seguidos', type: 'number', xs: 2 },
        { id: 'pausaEnvio', label: 'Tempo de Pausa Após Limite', type: 'number', xs: 2 }
    ]

    return (
        <React.Fragment>
            {!parametros ?
                <BsCircularLoading width={'70px'} title={'Carregando Paramêtros...'} /> :
                <div style={{ padding: 10 }}>
                    <Grid container spacing={3}>
                        {
                            inputs.map((input: any, i: number) => (
                                <Grid key={i} item xs={isMobileDevice() ? 12 : input.xs} paddingTop={input.type === 'break' ? '0px !important' : undefined}>
                                    {input.type !== 'break' &&
                                        <Inputs ref={(e: any) => itemEls.current[input.id] = e} inp={input} submitted={true} register={parametros} />
                                    }
                                </Grid>
                            ))
                        }

                        <Grid item sx={{ mt: 0.5 }} xs={window.innerWidth > 800 ? 2 : 12}>
                            <BsLoadingButton ref={btSalvarRef} fullWidth onClick={() => salvarParametros()}>Salvar</BsLoadingButton>
                        </Grid>
                    </Grid >
                </div>
            }
        </React.Fragment>
    )
}