import styled from "styled-components"
import { BsPaper } from "../../new_components/Paper";

const WrapperTitle = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
`
const Icon = styled.div`
    display: flex;
    margin-left: 15px;
    
`
const Title = styled.div`
    display: flex;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
`
const SubTitle = styled(Title)`
    font-size: 11px;
    font-weight: 100;
`

export const BsCard = (props: any) => {
    return (
        <BsPaper>
            <WrapperTitle style={{ color: 'var(--font-color)' }}>
                <Icon>{props.icon}</Icon>
                <div>
                    <Title>{props.title} </Title>
                    <SubTitle>{props.subtitle}</SubTitle>
                </div>
            </WrapperTitle>
            {props.children}
        </BsPaper>
    )
}       
