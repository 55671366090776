import { useEffect, useRef } from 'react'
import Box from '@mui/material/Box';
import { AppRoutes } from '../../routes';
import { Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { BsIconButtonAuxSideBar } from '../../components/BsIconButton';
import { isMobileDevice } from '../../utils';
import { BsDrawer } from '../../components/BsDrawer';
import { BsAppBar, BsMain } from '../../components/BsSideBarUtils';
import ReactGA from "react-ga4";
import { MenuPerfil } from './MenuPerfil';
import { LabelVencimento } from './LabelVencimento';
import { SideBar } from './SideBar';
import { SwitchTheme } from '../../components/SwitchTheme';
import { useThemeContext } from '../../theme';

export const Principal = () => {
    const location = useLocation();
    const { themeMode, toggleTheme } = useThemeContext();

    const labelRef = useRef<any>(null);
    const perfilRef = useRef<any>(null);

    const [expanded, setExpanded] = useState(!isMobileDevice());

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", title: 'Painel BitSafira - ' + location.pathname });
    }, [location]);

    useEffect(() => {
        labelRef.current.refreshMaster();
    }, [])

    const clickExpanded = () => {
        setExpanded(!expanded)
    }

    const clickItemMenu = (to: any) => {
        setExpanded(to === undefined ? true : !isMobileDevice());
    };

    return (
        <Box style={{ display: 'flex' }}>
            <BsAppBar position="fixed" open={expanded}>
                <Toolbar variant='dense' style={{ backgroundColor: 'var(--primary-color)' }} >
                    <BsIconButtonAuxSideBar onClick={clickExpanded} />
                    <SwitchTheme
                        defaultChecked={themeMode === 'dark'}
                        sx={{ position: `${isMobileDevice() ? 'fixed' : 'relative'}`, left: `${isMobileDevice() ? '3rem' : '0'}` }}
                        onChange={toggleTheme}
                    />
                    <MenuPerfil ref={perfilRef} />
                </Toolbar>
            </BsAppBar>

            <BsDrawer open={expanded} variant={isMobileDevice() ? "temporary" : "persistent"} >
                <SideBar clickExpanded={clickExpanded} clickItemMenu={clickItemMenu} />
            </BsDrawer>

            <BsMain open={expanded}>
                <div style={{ padding: '2px', marginTop: '48px', borderTopLeftRadius: '6px', backgroundColor: 'var(--background-color)' }}>
                    <LabelVencimento ref={labelRef} />
                    <AppRoutes />
                </div>
            </BsMain>
        </Box >
    );
}