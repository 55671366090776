import { Grid } from "@mui/material";
import React, { useImperativeHandle, useRef, useState } from "react";
import { BsDialog } from "../../../../components/BsDialog";
import { isMobileDevice, encrypt } from "../../../../utils";
import { Inputs } from "../../../../components/Inputs";

interface Props {
    senhaAtual: string;
    onFinish: any;
}

export const AlterarSenha = React.forwardRef((props: Props, ref) => {
    const itemEls = useRef<{ [key: string]: any }>({});
    const dialogRef = useRef<any>();

    const [submitted, setSubmitted] = useState(false);

    function setLoading(value: boolean) {
        dialogRef.current.setLoading(value);

        if (itemEls)
            Object.values(itemEls.current).forEach((elementRef: any) => { elementRef?.setLoading(value) });
    }

    function openModal() {
        setSubmitted(false);
        setLoading(false);
        dialogRef.current.openModal();
    };

    function closeModal() {
        dialogRef.current.closeModal();
    };

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal
    }));

    const alterarSenha = () => {
        setSubmitted(true);

        if (itemEls.current['atual'].validate() && itemEls.current['nova'].validate() && itemEls.current['confirmada'].validate()) {
            setLoading(true);
            props.onFinish(itemEls.current['nova'].getValue());
            setLoading(false);
        }
    }

    function validateAtual(value: any) {
        return {
            validated: encrypt(value) === props.senhaAtual,
            message: encrypt(value) === props.senhaAtual ? '' : 'Senha Atual Incorreta.'
        }
    }

    function validateNova(value: any) {
        const _validationConfirmacao = {
            validated: (value === itemEls.current['confirmada'].getValue()),
            message: (value !== itemEls.current['confirmada'].getValue()) ? 'Nova Senha diverge da confirmação.' : ''
        }

        itemEls.current['confirmada'].setValidations(_validationConfirmacao)

        if (!_validationConfirmacao.validated)
            return _validationConfirmacao
        else {
            return {
                validated: (value !== itemEls.current['atual'].getValue()),
                message: (value === itemEls.current['atual'].getValue() ? 'Nova Senha precisa ser diferente da Senha Atual.' : '')
            }
        }
    }

    function validaNovasSenhasIguais(value: any) {
        const _validationConfirmacao = {
            validated: (value === itemEls.current['nova'].getValue()),
            message: (value !== itemEls.current['nova'].getValue()) ? 'Nova Senha diverge da confirmação.' : ''
        }

        itemEls.current['nova'].setValidations(_validationConfirmacao)

        return _validationConfirmacao
    }

    const inputs = [
        { id: 'atual', type: 'text', label: 'Senha Atual', maxLength: 30, required: true, xs: 12, onValidate: validateAtual },
        { id: 'nova', type: 'text', label: 'Nova Senha', maxLength: 30, required: true, xs: 12, onValidate: validateNova },
        { id: 'confirmada', type: 'text', label: 'Confirmar Nova Senha', maxLength: 30, required: true, xs: 12, onValidate: validaNovasSenhasIguais }
    ]

    return (
        <BsDialog
            ref={dialogRef}
            maxWidth={'xs'}
            title={'Alterar Senha'}
            cancelClick={() => dialogRef.current.closeModal()}
            confirmClick={() => alterarSenha()}
            content={
                <Grid container spacing={2} sx={{ mt: 1 }} style={{ padding: 10 }}>
                    <Grid container spacing={2}>
                        {
                            inputs.map((input: any, i: number) => (
                                <Grid key={i} item xs={isMobileDevice() ? 12 : input.xs}>
                                    <Inputs ref={(e: any) => itemEls.current[input.id] = e} inp={input} submitted={submitted} />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid >
            }
        />
    )
});