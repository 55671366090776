import { Toolbar, Typography } from "@mui/material";
import Logo_Branca from '../../icones/Logo_Branco.png'
import { useAuth } from "../../contexts/AuthProvider/useAuth";

export const BsToolBarLogo = (props: any) => {
    const auth = useAuth();

    return (
        <Toolbar variant='dense' >
            <div style={{ width: 150 }}>
                <img
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: `${window.innerWidth > 800 ? '-0.5rem' : ''}` }}
                    src={Logo_Branca}
                    alt=''
                    width='120rem'
                />
            </div>
            <Typography variant="body2" style={{ fontSize: '11px', position: 'absolute', left: 52, bottom: -2, marginRight: '0.5rem' }}>
                {'ID Painel: ' + auth.masterId}
            </Typography>
            {props.children}
        </Toolbar>
    )
}