import React, { useImperativeHandle, useRef, useState } from "react"
import { BsDialog } from "../BsDialog"
import { isMobileDevice } from "../../utils";
import { Chip, Divider, Grid } from "@mui/material";
import { Inputs } from "../Inputs";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";

export const BsCadastroPadrao = React.forwardRef((props: any, ref) => {
    const auth = useAuth();

    const dialogRef = useRef<any>();
    const itemEls = useRef<{ [key: string]: any }>({});

    const [submitted, setSubmitted] = useState(false);

    function openModal() {
        dialogRef.current.openModal();
    }

    function setLoading(value: boolean) {
        dialogRef.current.setLoading(value);

        if (itemEls)
            Object.values(itemEls.current).forEach((elementRef: any) => {
                if (elementRef)
                    elementRef.setLoading(value)
            });
    }

    function closeModal() {
        dialogRef.current.closeModal();
        setSubmitted(false);
    }

    useImperativeHandle(ref, () => ({
        openModal: openModal,
        upsert: upsert
    }));

    // Requisição de cadastro ou edição de registros
    const upsert = (register: any, msg?: string) => {
        setLoading(true)

        async function execute() {
            await auth.requestPost(`${props.prefixApiCadastro}`, register).then(() => {
                toast.success(msg ? msg : 'Registro ' + (props.register.contador > 0 ? 'atualizado' : 'cadastrado') + ' com sucesso.');

                if (props.onAfterConfirm)
                    props.onAfterConfirm()

                closeModal();
            }).catch((error: any) => {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
                setLoading(false);
            })
        }

        execute();
    }

    // Validação dos inputs
    function validate() {
        var _return = true;

        if (itemEls)
            Object.values(itemEls.current).forEach((elementRef: any) => {
                if ((_return) && (elementRef))
                    if (!elementRef.validate())
                        _return = false

            });
        return _return
    }

    // Busca os inputs atualizados via referencia
    async function getInputs() {
        const _inputs: any[] = [];
        const _values: any = { ...props.register };
        var allInputs;

        if (props.model.externalInputs)
            allInputs = [...props.model.inputs, ...props.model.externalInputs]
        else
            allInputs = props.model.inputs;

        for (const inp of allInputs) {
            const _inpRef = itemEls.current[inp.id];

            if (_inpRef) {
                _inputs.push(_inpRef.getInput());

                if (inp.options)
                    _values[inp.id] = _inpRef.getValue()?.contador
                else if (inp.id.split(".").length > 1)
                    _values[inp.id.split(".")[0]] = { contador: _inpRef.getValue()?.contador };
                else
                    _values[inp.id] = _inpRef.getValue();
            }
        }

        return {
            inputs: _inputs,
            values: _values
        };
    }

    // Evento de salvar
    async function salvarClick() {
        getInputs().then(async (response: any) => {
            setSubmitted(true);
            setLoading(true);

            const options = { accept: true };

            if (props.onBeforeSave)
                await props.onBeforeSave(response.values, options);

            if (validate() && options.accept)
                upsert(response.values);
            else
                setLoading(false);
        });
    }

    return (
        <BsDialog
            ref={dialogRef}
            title={props.title}
            maxWidth={props.sizeDialog ? props.sizeDialog : (Number(props.model.inputs?.length) > 6) && (!isMobileDevice()) ? 'sm' : 'xs'}
            content={
                <>
                    <Grid container spacing={2} sx={{ mt: 0.05 }} >
                        {
                            props.headerDialog &&
                            <Grid item xs={12}>
                                {props.headerDialog()}
                            </Grid>
                        }
                        {
                            props.model.inputs.map((inp: any, i: number) => (
                                (
                                    ((inp.exclusiveNew && !props.register.contador) || (!inp.exclusiveNew)) &&
                                    ((inp.exclusiveEdit && props.register.contador) || (!inp.exclusiveEdit))
                                ) && (
                                    <Grid key={i} item xs={isMobileDevice() ? 12 : inp.xs ? inp.xs : 6}>
                                        <Inputs
                                            ref={(e: any) => itemEls.current[inp.id] = e}
                                            inp={{
                                                ...inp,
                                                onAfterChange: (_register: any) => {
                                                    if (inp.onAfterChange)
                                                        inp.onAfterChange(_register, itemEls);
                                                }
                                            }}
                                            register={props.register}
                                            submitted={submitted}
                                        />
                                    </Grid>
                                )
                            ))
                        }
                    </Grid>
                    {
                        (props.model.externalInputs) && (props.register.contador === 0) &&
                        < Grid container spacing={2} sx={{ mt: 0.05 }}>
                            <Grid item xs={12}>
                                <Divider sx={{ border: 'none' }}>
                                    <Chip label={props.model.externalInputsLabel} style={{ backgroundColor: 'var(--background-color)', color: '#fff', fontSize: 12, height: 20 }} />
                                </Divider>
                            </Grid>
                            {
                                props.model.externalInputs?.map((inp: any, i: number) => (
                                    <Grid key={i} item xs={isMobileDevice() ? 12 : inp.xs ? inp.xs : 6}>
                                        <Inputs
                                            ref={(e: any) => itemEls.current[inp.id] = e}
                                            inp={{
                                                ...inp,
                                                onAfterChange: (_register: any) => {
                                                    if (inp.onAfterChange)
                                                        inp.onAfterChange(_register, itemEls);
                                                }
                                            }}
                                            register={props.register}
                                            submitted={submitted}
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    }
                </>
            }
            cancelClick={closeModal}
            confirmClick={salvarClick}
        />
    )
})