
import { ListItemText, ListItemTextProps } from "@mui/material";

interface BsListItemTextProps extends ListItemTextProps {
    label?: string;
    fontWeight?: string;
}

export const BsListItemText = (props: BsListItemTextProps) => {
    return (
        <ListItemText
            primaryTypographyProps={{
                color: '#fff',
                fontWeight: props.fontWeight ? props.fontWeight : 'medium',
                fontSize: '0.85rem',
                variant: 'body2',
            }}
            sx={{ my: 0.35, whiteSpace: 'pre-line', padding: '3px 0px' }}
            primary={props.label}
            {...props}
        />
    )
}