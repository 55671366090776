import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useAuth } from "./contexts/AuthProvider/useAuth";

const lightTheme = createTheme({
    palette: {
        mode: "light",
        background: {
            default: "#f8f9fa",
            paper: "#ffffff",
        },
        text: {
            primary: "#000000",
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                },
            },
        },
    },
});

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: "#212529",
            paper: "#303030",
        },
        text: {
            primary: "#ffffff",
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "#212529",
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: "#212529",
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    backgroundColor: "#212529",
                },
            },
        },
    },
});

interface ThemeContextType {
    themeMode: "light" | "dark";
    toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProviderWrapper = ({ children }: { children: ReactNode }) => {
    const auth = useAuth();
    const isLoginPage = !auth.token;

    const [themeMode, setThemeMode] = useState<"light" | "dark">(
        (localStorage.getItem("theme") as "light" | "dark") || "light"
    );

    useEffect(() => {
        const activeTheme = isLoginPage ? "light" : themeMode;
        document.documentElement.setAttribute("data-theme", activeTheme);
        document.documentElement.setAttribute("data-bs-theme", activeTheme);
        localStorage.setItem("theme", activeTheme);
    }, [themeMode, isLoginPage]);

    const toggleTheme = () => {
        setThemeMode((prev) => (prev === "light" ? "dark" : "light"));
    };

    return (
        <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
            <ThemeProvider theme={isLoginPage ? lightTheme : themeMode === "light" ? lightTheme : darkTheme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export const useThemeContext = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error("useThemeContext deve ser usado dentro de ThemeProviderWrapper");
    }
    return context;
};
