import React, { useImperativeHandle, useRef, useState } from "react";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

export const BsLoadingButton: React.FC<LoadingButtonProps> = React.forwardRef((props, ref: any) => {
    const internalRef = useRef<any>(null);
    const [loading, setLoading] = useState(false);

    function click() {
        internalRef.current.click();
    }

    useImperativeHandle(ref, () => ({
        click,
        setLoading
    }));

    return (
        <LoadingButton
            ref={internalRef}
            size={props.size ? props.size : 'small'}
            variant={props.variant || 'contained'}
            loading={props.loading || loading}
            sx={{ color: props.variant !== 'outlined' ? '#fff' : '', ...props.sx }}
            style={{ backgroundColor: ((props.variant === 'outlined') ? 'transparent' : 'var(--primary-color)'), ...props.style }}
            {...props} />
    )
});