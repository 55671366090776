import { Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Logo from '../../images/logo512.png'

export const BsCircularLoading = (props: any) => {
    const theme = useTheme();

    const imageUrl = Logo;

    const [angle, setAngle] = useState(0);

    const rotateImage = (angulo: number) => {
        const newAngle = (angulo + 1) % 360;
        setAngle(newAngle);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            rotateImage(angle);
        }, 8);

        return () => clearInterval(intervalId);
    }, [angle]);

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <img src={imageUrl} alt="loading" tabIndex={-1} style={{ width: props.width ? props.width : '100px', marginBottom: '10px', marginTop: '10px', transform: `rotate(${angle}deg)`, filter: props.whiteLogo && 'grayscale(100%) brightness(10000%)' }} />
            <Typography variant={props.variantTitle ? props.variantTitle : 'h6'} style={{ color: (theme.palette.mode === 'dark' ? '#fff' : (props.colorTitle ? props.colorTitle : 'var(--primary-color)')), fontWeight: 'bold' }}> {props.title ? props.title : 'Por favor, aguarde...'}</Typography>
        </div >
    )
}