
import ReactApexChart from 'react-apexcharts';
import React from 'react';

export interface IProps {
    legendPosition?: any;
    height?: any;
    cellClick?: any;
}

interface IState {
    options?: any;
    series?: any;
    height?: any;
    data?: any;
}

class BsPieChart extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            series: [],
            data: [],
            height: props.height,
            options: {
                labels: [],
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: -5,
                            minAngleToShowLabel: 15
                        }
                    }
                },
                dataLabels: {
                    formatter(val: any, opts: any) {

                        const name = opts.w.globals.labels[opts.seriesIndex]
                        const value = opts.w.globals.series[opts.seriesIndex]
                        return [name, val.toFixed(1) + '% (' + value + ')']
                    }
                },
                legend: {
                    position: 'bottom'
                },
                stroke: {
                    width: 0.2
                },
                chart: {
                    events: {
                        dataPointSelection: (event: any, chartContext: any, config: any) => {
                            if (props.cellClick)
                                props.cellClick(this.state.data, config.w.globals.labels[config.dataPointIndex]);
                        }
                    }
                },
            }
        }
    }


    setSeries = (series: any[] | []) => {
        this.setState({
            series
        });
    }

    setData = (data: any[] | []) => {
        this.setState({
            data
        });
    }


    setLabels = (labels: any[] | []) => {
        this.setState({
            options: {
                ...this.state.options,
                labels,
            }
        });
    }

    render() {
        return (
            <div style={{ paddingBottom: '15px' }}>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    width={'95%'}
                    height={'450px'}
                    type="pie"
                    align="center"
                />
            </div>
        )
    }
}

export default BsPieChart;