import React, { useImperativeHandle } from "react"
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

export const LabelVencimento = React.forwardRef((props: any, ref: any) => {
    const auth = useAuth();

    useImperativeHandle(ref, () => ({ refreshMaster }));

    async function refreshMaster() {
        auth.refreshMaster();
    }

    return (
        <>
            {
                auth && (Number(auth.diasRestantes) <= 5) &&
                <div style={{
                    backgroundColor: Number(auth?.diasRestantes) < 3 ? 'var(--error-color)' : 'var(--warning-color)',
                    color: Number(auth?.diasRestantes) < 3 ? '#fff' : '#000',
                    textAlign: 'center',
                    borderRadius: '4px',
                    fontWeight: 500,
                    fontSize: 14,
                    padding: '2px 0px'
                }}>
                    {'Acesso a plataforma expira em: ' + auth?.diasRestantes + ' dia(s).'}
                </div>
            }
        </>
    )
});
